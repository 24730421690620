import React from 'react';
import {Switch} from 'antd';
import {withNamespaces} from "react-i18next";

export const PanelHeader = ({t, text, onChange, consented, total, lawfulUsage, policyURL, elemRef}) => (
    <div ref={elemRef}>
        {/*<div onClick={e => {*/}
        {/*    console.log(e);*/}
        {/*    e.stopPropagation();*/}
        {/*}} className='panel-header-title'>*/}
        {/*    <div className='panel-header-title-name'>*/}
        {/*        <span>{t('userRequests.panelHeaderDataPolicy')}</span>*/}
        {/*    </div>*/}
        {/*    <div className='panel-header-title-url'>*/}
        {/*        <a href={policyURL}>{t('userRequests.panelHeaderReadPolicy')}</a>*/}
        {/*    </div>*/}

        {/*</div>*/}
        {/*<div className='panel-divider'></div>*/}
        <div>
            <div className='panel-header'>
                <div><p className='title'>{text}</p>
                    <div
                        className='panel-header-count'>{t('userRequests.panelAllow')}{` ${consented} of ${total}`}</div>
                </div>
                <Switch disabled={lawfulUsage} defaultChecked={consented > 0} onChange={onChange}/>
            </div>
        </div>
    </div>

);

export const PanelHeaderWithNamespace = withNamespaces()(PanelHeader);
