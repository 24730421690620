import React, {Component, createRef} from 'react';
import {observer} from 'mobx-react';
import './collapse.css';

import {Collapse, Icon, Spin} from 'antd';
import {PanelHeader, PanelHeaderWithNamespace} from './PanelHeader';
import {SubCollapse} from './SubCollapse';
import {store} from 'Provider/store';
import {withNamespaces} from "react-i18next";

const Panel = Collapse.Panel;


const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

@observer
class CollapseUsage extends Component {

    constructor(props) {
        super(props);
        this.elemRefs = [];

        this.state = {
            openKey: [],
            scrolled: false
        };

        this.delta = this.delta.bind(this);
    }

    delta(index) {
        this.setState({openKey: index});

    }

    componentDidMount() {
        store.fetchPurposes();
        console.log("Component mounted");
    }

    // Scroll to ref function
    scrollToMyRef = (refId) => {

        if (this.elemRefs[refId] !== undefined) {
            if (!store.qrStore.isPurposeScrolled) {
                this.elemRefs[refId].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
                const elem = document.getElementsByClassName("panel-usage-" + refId);
                if (elem !== undefined) {
                    elem[0].style.border = "1px solid";
                }

                // updating scroll status.
                store.qrStore.isPurposeScrolled = true;
            }

        }
    };


    componentDidUpdate(prevProps, prevState, snapshot) {

        this.scrollToMyRef(store.qrStore.purposeId);

    }


    handleSwitch = (purposeId, value, e) => {
        e.stopPropagation();
        let consented = value ? 'Allow' : 'Disallow';
        store.updateAllAttribute(consented, purposeId);
    };

    changeAttributeStatus = (purposeId, attributeId, e) => {
        console.log('DEBUG :', `PURPOSE ID : ${purposeId}`, `ATTRIBUTE ID : ${attributeId}`, `Value : ${e.target.value}`);
        // console.log(e);
        const currentStatus = e.target.value;
        if (currentStatus === 'Allow' || currentStatus === 'Disallow') {
            store.updateAttribute(currentStatus, 0, purposeId, attributeId);
        } else if (currentStatus === 'Askme') {
            store.save();
            store.updateAttributeUiStore(purposeId, attributeId, currentStatus, 'Consented');
            store.updateAttribute('Askme', store.config.askMe.minDays, purposeId, attributeId);
        }
    };
    changeDays = (purposeId, attributeId, value) => {
        // console.log('Days',value);
        store.updateAttribute('Askme', value, purposeId, attributeId);
    };

    render() {
        const {isFetching, data, loadingUiStore} = store.purposesAndConsents;
        const {t} = this.props;
        let openKey = "";
        if (isFetching) {
            return (
                <div className='dashboard-spin'><Spin tip={t('userPurposes.fetchingMessage')} indicator={antIcon}/>
                </div>
            )
        } else {
            data.forEach(function (purpose, index) {
                if (purpose['Purpose']['ID'] === store.qrStore.purposeId) {
                    if (!store.qrStore.isPurposeToggled) {
                        openKey = index;
                        store.qrStore.isPurposeToggled = true;
                    }
                    return
                }
            });
        }

        this.elemRefs = data.reduce((acc, value) => {
            acc[value['Purpose']['ID']] = createRef();
            return acc;
        }, {});

        // Todo : Need to fix the toggle, purpose highlight.

        return (
            <Collapse
                className='collapse-usage'
                bordered={false}
                defaultActiveKey={openKey.toString()}
                expandIcon={({isActive}) => <Icon type="caret-right" rotate={isActive ? 90 : 0}/>}
            >


                {data.map((purpose, i) =>

                    <Panel header={<Spin key={i} spinning={loadingUiStore[purpose['Purpose']['ID']]['isLoading']}>
                        <PanelHeaderWithNamespace
                            elemRef={this.elemRefs[purpose['Purpose']['ID']]}
                            policyURL={purpose['Purpose']['PolicyURL']}
                            lawfulUsage={purpose['Purpose']['LawfulUsage']}
                            consented={purpose['Count']['Consented']} total={purpose['Count']['Total']}
                            onChange={(value, e) => this.handleSwitch(purpose['Purpose']['ID'], value, e)}
                            text={`${purpose['Purpose']['Description']}`}/>
                    </Spin>} key={i} className={`panel-usage panel-usage-${purpose['Purpose']['ID']}`}>


                        {purpose['Consents'].map((consent, i) =>
                            <Spin key={i} spinning={loadingUiStore[purpose['Purpose']['ID']][consent['ID']]}>
                                <SubCollapse
                                    key={i}
                                    lawfulUsage={purpose['Purpose']['LawfulUsage']}
                                    title={consent['Description']}
                                    consented={consent['Status']['Consented']}
                                    days={consent['Status']['Days']}
                                    onRadioClick={(e) => this.changeAttributeStatus(purpose['Purpose']['ID'], consent['ID'], e)}
                                    onSliderChange={(value) => this.changeDays(purpose['Purpose']['ID'], consent['ID'], value)}/>
                            </Spin>
                        )}
                    </Panel>
                )}


            </Collapse>
        );
    }
}

export default withNamespaces()(CollapseUsage);
