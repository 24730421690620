import React, { Component } from "react";

import OtpInput from "react-otp-input";
import { observer } from "mobx-react";

@observer
class CustomOtpInput extends Component {
  constructor(props) {
    super(props);

    const value = props.value || {};

    this.state = {
      rawValue: ""
    };

    this.triggerChange({ rawValue: "" });
  }

  componentDidMount() {
    this.setState({ rawValue: "" });
    this.triggerChange({ rawValue: "" });
  }

  triggerChange = changedValue => {
    // Should provide an event to pass value to Form.
    const { onChange } = this.props;
    if (onChange) {
      onChange({
        ...this.state,
        ...changedValue
      });
    }
  };

  render() {
    const handleOnChange = value => {
      this.setState({ rawValue: value });
      this.triggerChange({ rawValue: value });
    };

    return (
      <OtpInput
        inputStyle={{
          width: "100%",
          maxWidth: "3rem",
          maxHeight: "3rem",
          padding: "0 0.5rem",
          margin: "0 0.25rem",
          fontSize: "2rem",
          borderRadius: 4,
          border: "1px solid rgba(0,0,0,0.3)"
        }}
        value={this.state.rawValue}
        onChange={handleOnChange}
        numInputs={6}
        separator={<span>-</span>}
        shouldAutoFocus
      />
    );
  }
}

export default CustomOtpInput;
