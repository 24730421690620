import {observable, computed, action} from "mobx";
import i18n from "../localization/i18n";
import Auth from "../authorization/auth";
import Services from "../services";
import moment from "moment";
import {history} from "../history";
import {getSession} from "../authorization/utils";

export const Status = {
    initiated: "Request initiated",
    acknowledged: "Request acknowledged",
    cancelled: "Request cancelled by user",
    processedWithoutAction: "Request processed without action",
    processedWithAction: "Request processed with action"
};
export const RequestTypes = {
    dataDownload: "Download Data",
    dataDelete: "Delete Data",
    dataUpdate: "Update Data"
};

class Store {
    @observable user = {
        name: "",
        email: "",
        lastVisit: ""
    };
    @observable config = {};
    @observable authStore = {
        isLoading: false,
        isRemember: true,
        error: ""
    };

    @observable qrStore = {
        isRedirected: false,
        fromPath: "",
        qrType: "",
        purposeId: "",
        shortLink: "",
        isPurposeToggled: false,
        isPurposeScrolled: false
    };

    @observable subscriptionStore = {
        isFetching: true,
        isLoading: false,
        isSubscribed: false,
        method: "",
        error: ""
    };

    @observable organizationStore = {
        isFetching: true,
        error: null,
        data: {},
        coverImageURI: null,
        logoImageURI: null,
        coverImageDescription: "No Data",
        logoImageDescription: "No Data"
    };
    @observable purposesAndConsents = {
        isFetching: true,
        data: null,
        ID: null,
        loadingUiStore: {}
    };
    @observable dataRequest = {
        isFetching: true,
        data: null,
        form: null
    };
    @observable historyLogs = {
        logs: [],
        isFetching: true
    };

    @computed get dataDeleteRequest() {
        if (this.dataRequest.data) {
            return this.dataRequest.data.filter(
                item => item["requestType"] === RequestTypes.dataDelete
            );
        }
    }

    @computed get dataDownloadRequest() {
        if (this.dataRequest.data) {
            return this.dataRequest.data.filter(
                item => item["requestType"] === RequestTypes.dataDownload
            );
        }
    }

    // QR store actions
    @action setFromPath = fromPath => {
        try {
            // extracting shortlink from the path
            let shortlink = fromPath.split("&");
            shortlink = shortlink[shortlink.length - 1];
            shortlink = shortlink.split("=");
            shortlink = shortlink[shortlink.length - 1];

            // extracting qrType from the path.
            let qrType = fromPath.split("&");
            qrType = qrType[qrType.length - 2];
            qrType = qrType.split("=");
            qrType = qrType[qrType.length - 1];

            try {
                // extracting purpose id from the path.
                let purposeId = fromPath.split("&");
                purposeId = purposeId[purposeId.length - 3];
                purposeId = purposeId.split("=");
                purposeId = purposeId[purposeId.length - 1];

                if (qrType === "purpose") {
                    this.qrStore.purposeId = purposeId;
                }
            } catch (e) {
            }

            if (qrType !== "") {
                this.qrStore.qrType = qrType;
                this.qrStore.fromPath = fromPath;
                this.qrStore.shortLink = shortlink;
            }
        } catch (e) {
        }
    };

    // QR store computations
    @computed get getFromPath() {
        return this.qrStore.fromPath;
    }

    @computed get getQrType() {
        return this.qrStore.qrType;
    }

    @action handleSubscription = () => {
        const request = services.fetchSubscriptionStatus();

        if (request) {
            request
                .then(res => {
                    // if subscribed
                    // Todo :  handle QR redirection

                    // updating subscription status
                    this.subscriptionStore.isSubscribed = true;

                    // check if the qrType is not empty
                    if (store.qrStore.qrType !== "") {
                        // checking if not yet redirected
                        if (store.qrStore.isRedirected !== true) {
                            if (store.qrStore.qrType === "purpose") {
                                store.qrStore.isRedirected = true;
                                history.push("/dashboard/usagepurpose");
                            } else if (store.qrStore.qrType === "organization") {
                                store.qrStore.isRedirected = true;
                                history.push("/dashboard/usagepurpose");
                            } else {
                                store.qrStore.isRedirected = true;
                                history.push("/dashboard");
                            }
                        }
                    } else {
                        store.qrStore.isRedirected = true;
                        history.push("/");
                    }
                })
                .catch(error => {
                    if (error.request.status === 404) {
                        // not subscribed

                        // not subscribed.
                        // check subscription method

                        const request = services.fetchSubscribeMethods();

                        if (request) {
                            request
                                .then(res => {
                                    this.subscriptionStore.method = res.data["Method"];

                                    // updating fetching status
                                    this.subscriptionStore.isFetching = false;

                                    if (this.subscriptionStore.method === "Key-Based") {
                                        // redirect to subscription page
                                        history.push("/dashboard/subscribe");
                                    } else {
                                        // subscribing users without subscription key
                                        const subscribeToOrgRequest = store.handleSubscribeToOrg(
                                            ""
                                        );

                                        if (subscribeToOrgRequest) {
                                            subscribeToOrgRequest
                                                .then(res => {
                                                    if (res.status === 200) {
                                                        console.log(res);

                                                        store.subscriptionStore.isSubscribed = true;
                                                    }
                                                })
                                                .catch(error => {
                                                    console.log(error);
                                                });
                                        }
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });
                        }
                    } else {
                        console.log(error);
                    }
                });
        }
    };

    @action handleSubscribeToOrg = subscriptionKey => {
        const request = services.addUserToOrg(subscriptionKey);
        return request;
    };

    @action fetchDataRequest = () => {
        let dataRequestsList = [];
        Promise.all([services.getAlldataDelete(), services.getAlldataDownload()])
            .then(values => {
                console.log("Promise all : ", values);
                values.forEach(res => {
                    if (res.status === 200 && res.data !== null) {
                        dataRequestsList = [
                            ...dataRequestsList,
                            ...this.toDataRequestTableModal(res.data)
                        ];
                    }
                });
                this.dataRequest.data = dataRequestsList;
                console.log(dataRequestsList);
                this.dataRequest.isFetching = false;
            })
            .catch(error => {
                console.error("Error in fetch data requests", error);
            });
    };
    @action fetchLogs = () => {
        const request = services.fetchLogs();
        if (request) {
            request
                .then(res => {
                    if (res.status === 200) {
                        this.historyLogs.logs = this.toLogsTableModal(
                            res.data["ConsentHistory"]
                        );
                        this.historyLogs.isFetching = false;
                        // console.log(res.data['ConsentHistory']);
                    }
                })
                .catch(error => {
                    console.error("Error in fetch history", error);
                    this.historyLogs.isFetching = false;
                });
        }
    };
    @action fetchOrganization = () => {
        const request = services.fetchOrganization();
        if (request) {
            request
                .then(res => {
                    if (res.status === 200) {
                        if (res.data.Organization) {
                            const organization = res.data.Organization;
                            // console.log('Organization :',organization);
                            this.organizationStore.data = {...organization};

                            //Fetch images
                            if (organization.CoverImageURL) {
                                this.organizationStore.coverImageDescription = "Loading...";
                                services
                                    .fetchImage(organization.CoverImageURL)
                                    .then(
                                        dataURI => (this.organizationStore.coverImageURI = dataURI)
                                    )
                                    .catch(error => {
                                        console.error(error);
                                        this.organizationStore.coverImageDescription =
                                            "Failed to fetch";
                                    });
                            }
                            if (organization.LogoImageURL) {
                                this.organizationStore.logoImageDescription = "Loading...";
                                services
                                    .fetchImage(organization.LogoImageURL)
                                    .then(
                                        dataURI => (this.organizationStore.logoImageURI = dataURI)
                                    )
                                    .catch(error => {
                                        console.error(error);
                                        this.organizationStore.logoImageDescription =
                                            "Failed to fetch";
                                    });
                            }
                            this.organizationStore.isFetching = false;
                        } else {
                            console.error("no Organization key in response");
                        }
                    }
                })
                .catch(error => {
                    console.error("Error in fetch organization", error);
                    this.organizationStore.isFetching = false;
                    this.organizationStore.error = error.response
                        ? `Fetching Organization is failed with status of ${error.response &&
                        error.response.status}`
                        : `${error.message}`;
                });
        }
    };

    @action changeLanguage = lng => {
        // this.iStrings.setLanguage(lng);
        localStorage.setItem("lng", lng);
        i18n.changeLanguage(lng);
    };

    @action fetchPurposes = () => {
        const request = services.fetchPurposesAndConsents();
        if (request) {
            request
                .then(res => {
                    if (res.status === 200) {
                        console.log(res.data);
                        this.purposesAndConsents.data = res.data["ConsentsAndPurposes"];
                        this.purposesAndConsents.ID = res.data["ID"];
                        const loadingStore = this.mapToLoadingUiStore([
                            ...this.purposesAndConsents.data
                        ]);
                        //console.log(loadingStore);
                        this.purposesAndConsents.loadingUiStore = loadingStore;
                        this.purposesAndConsents.isFetching = false;
                    }
                })
                .catch(error => console.error(error));
        }
    };

    getPurposeById = (id, purposeAndConsents) => {
        return purposeAndConsents.find(x => x["Purpose"]["ID"] === id);
    };
    getAttributeById = (purposeId, attributeId) => {
        const purpose = this.getPurposeById(
            purposeId,
            this.purposesAndConsents.data
        );
        return purpose["Consents"].find(x => x["ID"] === attributeId);
    };
    getAttributesById = purposeId => {
        const purpose = this.getPurposeById(
            purposeId,
            this.purposesAndConsents.data
        );
        return purpose["Consents"];
    };

    @action
    updateAttributeUiStore = (purposeId, attributeId, value, key) => {
        let attribute = this.getAttributeById(purposeId, attributeId);
        attribute["Status"][key] = value;
    };
    @action
    updateAllAttributeUiStore = (purposeId, value, key) => {
        let attributes = this.getAttributesById(purposeId);
        attributes.forEach(att => {
            att["Status"][key] = value;
        });
    };
    @action
    filterPurposeById = purposeId => {
        let filtered = this.purposesAndConsents.data.filter(
            x => x["Purpose"]["ID"] != purposeId
        );
        console.log("filterred", filtered);
        this.purposesAndConsents.data = filtered;
    };
    @action
    maintainCount = (purposeId, value) => {
        let purpose = this.getPurposeById(purposeId, this.purposesAndConsents.data);
        switch (value) {
            case "Allow":
                purpose["Count"]["Consented"]++;
                break;
            case "Disallow":
                purpose["Count"]["Consented"]--;
                break;
            default:
                console.warn("value should be Allow or Disallow ");
        }
    };
    updateAllAttribute = (value, purposeId) => {
        this.purposesAndConsents.loadingUiStore[purposeId]["isLoading"] = true; //Set loading mask as true
        let consentsId = this.purposesAndConsents.ID;
        let patch = {
            Consented: value
        };
        const request = services.updateAllAttributeStatus(
            consentsId,
            purposeId,
            patch
        );
        if (request) {
            request
                .then(res => {
                    if (res.status === 200) {
                        let responseData = res.data;
                        if (responseData["ConsentsAndPurposes"]) {
                            this.purposesAndConsents.data =
                                responseData["ConsentsAndPurposes"]; //Push updated purpose into store
                            // message.success('Successfully updated all attributes.');
                            this.purposesAndConsents.loadingUiStore[purposeId][
                                "isLoading"
                                ] = false; //Set loading mask as true
                        } else {
                            console.log(
                                'failed to update all attribute.Response doesnt contain "ConsentsAndPurposes"'
                            );
                            this.purposesAndConsents.loadingUiStore[purposeId][
                                "isLoading"
                                ] = false; //Set loading mask as true
                        }
                    }
                })
                .catch(error => {
                    this.purposesAndConsents.loadingUiStore[purposeId][
                        "isLoading"
                        ] = false; //Set loading mask as true
                    // message.error('Failed to update all attribute.');
                    this.restore();
                    console.error(error);
                });
        }
    };

    updateAttribute = (value, days, purposeId, attributeId) => {
        this.purposesAndConsents.loadingUiStore[purposeId][attributeId] = true; //set loading mask as true
        let consentsId = this.purposesAndConsents.ID;
        let patch = {
            Consented: value === "Askme" ? "Allow" : value,
            Days: days
        };
        const request = services.updateAttributeStatus(
            consentsId,
            purposeId,
            attributeId,
            patch
        );
        if (request) {
            request
                .then(res => {
                    if (res.status === 200) {
                        this.purposesAndConsents.loadingUiStore[purposeId][
                            attributeId
                            ] = false; //Release loading mask
                        if (res.data["Status"] === 200) {
                            // message.success(res.data.Msg);
                            if (this.saved) {
                                this.saved = null;
                            }
                            this.updateAttributeUiStore(
                                purposeId,
                                attributeId,
                                value,
                                "Consented"
                            );
                            this.updateAttributeUiStore(purposeId, attributeId, days, "Days");
                            this.maintainCount(purposeId, value);
                        } else {
                            console.log("failed to update attribute.");
                            this.purposesAndConsents.loadingUiStore[purposeId][
                                attributeId
                                ] = false; //Release loading mask
                            this.restore();
                        }
                    }
                })
                .catch(error => {
                    this.purposesAndConsents.loadingUiStore[purposeId][
                        attributeId
                        ] = false; //Release loading mask
                    // message.error('Failed to update attribute.');
                    this.restore();
                    console.error(error);
                });
        }
    };
    sendDataRequest = () => {
        const form = this.dataRequest.form;
        if (form) {
            form.validateFields((err, values) => {
                if (!err) {
                    // console.log('Received values of form: ', values);
                    let requestType = values["requestType"];
                    switch (requestType) {
                        case RequestTypes.dataDelete:
                            //Call data delete API
                            services.postDataDelete().then(res => {
                                if (res.status === 200) {
                                    console.log("data-delete requested successfully");
                                    store.fetchDataRequest(); //Update Table
                                }
                            });
                            break;
                        case RequestTypes.dataDownload:
                            //Call data download API
                            services.postDataDownload().then(res => {
                                if (res.status === 200) {
                                    console.log("data-download requested successfully");
                                    store.fetchDataRequest(); //Update Table
                                }
                            });
                            break;
                        case RequestTypes.dataUpdate:
                            //Call data update API
                            break;
                        default:
                            console.warn("Unknown data request type!");
                    }
                }
            });
        }
    };
    save = () => {
        this.saved = [...this.purposesAndConsents.data];
    };
    restore = () => {
        if (this.saved) {
            action(() => (this.purposesAndConsents.data = [...this.saved]));
        } else {
            console.warn("Nothing to restore.");
        }
    };

    mapToLoadingUiStore = consentsAndPurpose => {
        const loader = consentsAndPurpose.reduce((acc, cur) => {
            let child = {isLoading: false};
            let purposeId = cur["Purpose"]["ID"];
            let consentsArr = cur["Consents"];
            let consentIds = consentsArr.map(x => x["ID"]);
            consentIds.forEach(id => (child[id] = false));
            acc[purposeId] = child;
            return acc;
        }, {});

        return loader;
    };
    toLogsTableModal = arr => {
        let rows = [...arr];
        let tableData = rows.map(row => {
            // let purpose = this.getPurposeById(row['PurposeID'],this.purposesAndConsents.data);
            return {
                timestamp: moment(row["TimeStamp"]).format("MMM-DD-YYYY HH:mm:ss A"),
                log: row["Log"]
            };
        });
        console.log(tableData);
        return tableData;
    };
    toDataRequestTableModal = (arr = []) => {
        return arr.map(row => {
            return {
                requested: moment(row["RequestedDate"], "YYYY-MM-DD HH:mm:ss Z").format(
                    "MMM-DD-YYYY HH:mm:ss A"
                ),
                closed:
                    row["StateStr"] == Status.acknowledged ||
                    row["StateStr"] == Status.initiated
                        ? "N/A"
                        : moment(row["ClosedDate"], "YYYY-MM-DD HH:mm:ss Z").format(
                        "MMM-DD-YYYY HH:mm:ss A"
                        ),
                requestType: row["TypeStr"],
                status: row["StateStr"],
                ID: row["ID"],
                key: row["ID"]
            };
        });
    };

    // computed((purposeId)=>{
    // 	let purpose = this.getPurposeById(purposeId);
    // 	let consentsArr = [...purpose['Consents']];
    // 	let consentedCount = consentsArr.reduce((acc,cur)=>{
    // 		if(cur['Status']['Consented'] === 'Allow'){
    // 			acc.count++;
    // 		}
    // 		return acc;
    // 	},{count:0})
    // 	return consentedCount.count;
    // })
}

export const store = new Store();
export const auth = new Auth(store);
export const services = new Services(store, auth);
// export default store;
