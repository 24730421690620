import React, {Component} from "react";

//Store
import {store, auth} from "../../Provider/store";
import {observer} from "mobx-react";

import {LanguageSelector} from "../Login/LanguageSelector";

import {Logo} from "../Login/Logo";
import defaultLogo from 'assets/icons/igrant.io_200X200.jpg';

import OpenInApp from "../OpenInApp/OpenInApp";

//Styles
import "./subscribetoorganization.css";

//i18n
import {withNamespaces} from "react-i18next";
import {Button, Divider, Form, Input, Spin} from "antd";
import {antIcon} from "../Login/antIcon";
import {Link} from "react-router-dom";
import ReactDOM from "react-dom";
import {history} from "../../history";


@observer
class SubscribeToOrganization extends Component {

    state = {
        values: {}
    };

    componentDidMount() {
        const element = ReactDOM.findDOMNode(this);
        element.addEventListener("keydown", e => {
            this.handleKeydown(e);
        });
    }

    // handling form submission when enter key is pressed
    handleKeydown(e) {

        if ((e.keyCode === 13) && (e.shiftKey === false)) {
            e.preventDefault();
            this.handleSubscribe();
        }

    }

    // clears error
    clearError = () => {
        if (store.subscriptionStore.error) {
            store.subscriptionStore.error = '';
        }
    };

    toInputUppercase = e => {
        e.target.value = ("" + e.target.value).toUpperCase();

        if (e.target.value.length === 4 || e.target.value.length === 9) {
            e.target.value = e.target.value + "-"
        }
    };

    // handles subscription
    handleSubscribe = () => {


        this.clearError();

        this.props.form.validateFields((err, values) => {

            this.setState({values});

            if (!err) {

                if (values.SubscriptionKey) {
                    store.subscriptionStore.isLoading = true;

                    const subscribeToOrgRequest = store.handleSubscribeToOrg(values.SubscriptionKey);

                    if (subscribeToOrgRequest) {

                        subscribeToOrgRequest.then((res) => {

                            if (res.status === 200) {
                                console.log(res);

                                store.subscriptionStore.isSubscribed = true;
                                store.subscriptionStore.isLoading = false;

                                // redirecting to dashboard
                                history.push("/");

                                // check if the qrType is not empty
                                if (store.qrStore.qrType !== "") {

                                    // checking if not yet redirected
                                    if (store.qrStore.isRedirected !== true) {

                                        if (store.qrStore.qrType === "purpose") {
                                            store.qrStore.isRedirected = true;
                                            history.push("/dashboard/usagepurpose");
                                        } else if (store.qrStore.qrType === "organization") {
                                            store.qrStore.isRedirected = true;
                                            history.push("/dashboard/usagepurpose");
                                        } else {
                                            store.qrStore.isRedirected = true;
                                            history.push("/dashboard");
                                        }
                                    }

                                } else {
                                    store.qrStore.isRedirected = true;
                                    history.push("/");
                                }
                            }

                        }).catch((error) => {
                            this.handleError(error);
                        })


                    }


                }

            }


        });


    };

    handleError = (error) => {
        if (error.response.data.Message !== undefined) {
            store.subscriptionStore.error = error.response.data.Message
        } else {
            store.subscriptionStore.error = error.response.data.error_description
        }
        store.subscriptionStore.isLoading = false;
        console.error(error);
    };

    handleLogout = () => {
        auth.logout()
    };

    render() {
        const {t} = this.props;
        const {url} = store.config.logo;
        const {error} = store.subscriptionStore;
        const loading = store.subscriptionStore.isLoading;
        const {getFieldDecorator} = this.props.form;

        return (
            <div className="subscribe-org-container">
                <div className='subscribe-org-container-main'>
                    <div className='logo'><img src={url ? url : defaultLogo}/></div>
                    <p className="login-title">Subscribe to organization</p>
                    <div className='subscribe-org-content-header'>
                        <h4>Enter the 12-digit subscription key</h4>
                        <p>To subscribe, enter the subscription key provided by us</p>
                    </div>
                    <Form onSubmit={this.handleForgotPassword}
                          className="forgot-password-form">
                        <div className='forgot-password-input-group'>
                            <Form.Item className="name-input forgot-password-form-input">
                                {getFieldDecorator("SubscriptionKey", {
                                    rules: []//[{ required: true, message: t("messages.username") }]
                                })(
                                    <Input
                                        type="text"
                                        placeholder={t("subscriptionKey")}
                                        size="large"
                                        onInput={this.toInputUppercase}
                                        onChange={this.clearError}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        {/* Registration continue */}
                        <div className="forgot-password-continue-box">
                            {loading ? <Spin className='login-btn' indicator={antIcon}/> :
                                <Button className="forgot-password-continue-button" type="primary"
                                        onClick={this.handleSubscribe}>{t("subscribe")}</Button>}

                        </div>

                        {/* Registration input errors */}
                        {error && <div className='forgot-password-error'>{error}</div>}

                        <Divider className="registration-divider"/>

                        {/* Sign in section */}
                        <div className="register-actions">
                            <span>Click </span>
                            <a onClick={this.handleLogout}>here</a>
                            <span> to logout</span>
                        </div>

                    </Form>
                </div>

                <OpenInApp/>

            </div>
        )

    }

}


const WrappedLogin = Form.create({name: "subscribe_to_organization"})(SubscribeToOrganization);

export default withNamespaces()(WrappedLogin);
