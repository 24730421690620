import React, {Component} from "react";
//Styles
import "./App.css";
//Router
import {Route, Switch, Router, Redirect} from "react-router-dom";
//history
import {history} from "./history";
//Containers
import {Login, Register, ForgotPassword, DefaultLayout} from "./Components";
//Authorization
// import Store from "Provider/store";
// import Auth from "./authorization/auth";
import {auth, store} from 'Provider/store';
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import SubscribeToOrganization from "./Components/SubscribeToOrganization/SubscribeToOrganization";

const supportsHistory = "pushState" in window.history;

const PrivateRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={props =>
            auth.isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />
            )
        }
    />
);

// listening to path changes and checking if it came from QR code scan
history.listen((location, action) => {
    if (location.state !== undefined) {
        if (location.state.from !== undefined) {
            store.setFromPath(location.state.from.search);
        }

    }

});

class App extends Component {

    render() {

        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/login" name="Login Page" component={Login}/>
                    <Route exact path="/register" name="Registration Page" component={Register}/>
                    <Route exact path="/privacy-policy" name="Privacy Policy" component={PrivacyPolicy}/>
                    <Route exact path="/dataskyddspolicy" name="Privacy Policy" component={PrivacyPolicy}/>
                    <Route exact path="/forgot-password" name="Recover you account" component={ForgotPassword}/>
                    <PrivateRoute
                        path="/dashboard"
                        name="dashboard"
                        component={DefaultLayout}
                    />
                    <Redirect exact from="/" to="/dashboard"/>
                </Switch>
            </Router>
        );
    }
}

export default App;
