import axios from "axios";
// import {store} from "Provider/store";
// import Config from "app.config.js";
import {setSession} from "./utils";
import {message} from "antd";


import {history} from "../history";
// const loginEndpoint = store.config.serviceUrls.login;
// const logoutEndpoint = store.config.serviceUrls.logout;

class Auth {
    constructor(store) {
        this.store = store;
        // this.loginEndpoint = store.config.serviceUrls && store.config.serviceUrls.login || null;
        // this.logoutEndpoint = store.config.serviceUrls && store.config.serviceUrls.logout || null;
    }

    error = null;
    isAuthenticated = false;
    accessToken = null;

    get token() {
        return this.accessToken;
    }

    // for registering user.
    iGrantRegister = (username, name, password, phone) => {
        if (password && username && phone) {
            let body = {
                username: username,
                password: password,
                name: name,
                phone: phone
            };

            return axios.post(`${this.store.config.serviceUrls.iGrantRegister}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }
    };

    // for registering user.
    register = (username, name, password, phone) => {
        if (password && username && phone) {
            let body = {
                username: username,
                password: password,
                name: name,
                phone: phone
            };

            return axios.post(`${this.store.config.serviceUrls.register}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }
    };

    login = (username, password) => {
        this.authorize(username, password);
    };

    sendOtp = (phone, username) => {
        if (phone && username) {
            let body = {
                phone: phone,
                email: username
            };

            return axios.post(`${this.store.config.serviceUrls.verifyPhone}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }
    };

    validateEmail = (username) => {
        if (username) {
            let body = {
                email: username
            };

            return axios.post(`${this.store.config.serviceUrls.validateEmail}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }
    };

    validatePhone = (phone) => {
        if (phone) {
            let body = {
                phone: phone
            };

            return axios.post(`${this.store.config.serviceUrls.validatePhone}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }
    };

    validateOtp = (phone, otp) => {
        if (phone && otp) {
            let body = {
                phone: phone,
                otp: otp
            };

            return axios.post(`${this.store.config.serviceUrls.verifyOtp}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }
    };

    // for creating user.
    backgroundLogin = (username, password) => {
        if (username && password) {
            let body = {
                Username: username,
                Password: password
            };
            return axios.post(`${this.store.config.serviceUrls.login}`, body, {

                headers: {"Content-Type": "application/json"}
            })
        }

    };

    // forgot password
    forgotPassword = (username) => {

        if (username) {
            let body = {
                username: username,
            };

            return axios.put(`${this.store.config.serviceUrls.forgotPassword}`, body, {
                headers: {"Content-Type": "application/json"}
            })
        }

    };

    authorize = (username, password) => {
        if (username && password) {
            let body = {
                Username: username,
                Password: password
            };
            axios
                .post(`${this.store.config.serviceUrls.login}`, body, {
                    headers: {"Content-Type": "application/json"}
                })
                .then(this.handleAuthentication)
                .catch(error => {
                    // if(error.response)message.error(error.response.data.error_description);
                    error.response ? this.store.authStore.error = error.response.data.error_description : this.store.authStore.error = error.message;
                    this.store.authStore.isLoading = false;
                    console.error(error);
                })
        }
    };

    // removes user details from localStorage
    logout = () => {
        let body = {
            RefreshToken: this.refreshToken,
            ClientID: 'igrant-ios-app'
        };
        // console.log('DEBUG :',body);
        axios.post(this.store.config.serviceUrls.logout, body, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.accessToken}`
            }
        })
            .then(res => {
                console.log('logout successfully.');
                this.clearSession();
                this.isAuthenticated = false;
                // navigate to the dashboard route
                history.replace("/dashboard");
            })
            .catch(error => {
                console.error(error);
            })
        // this.clearSession();
        // this.isAuthenticated = false;
        // // navigate to the dashboard route
        // history.replace("/dashboard");
    };

    // parses the result after authentication from URL hash
    handleAuthentication = res => {
        // console.log("DEBUG", store);
        this.store.authStore.isLoading = false;
        if (res.status === 200) {
            let result = res.data;
            this.error = null;
            // if (result.access_token && result.refresh_token && result.expires_in) {
            if (result.Token) {
                if (this.store.authStore.isRemember) {
                    setSession({
                        ...result.Token,
                        userId: result.User.ID,
                        username: result.User.Name,
                        email: result.User.Email,
                        lastVisit: result.User.LastVisit
                    });
                }
                this.accessToken = result.Token.access_token;
                this.refreshToken = result.Token.refresh_token;
                // this.clientID = result.Client.Token;
                this.userId = result.User.ID;

                // message.success("Logged in successfully");
                this.isAuthenticated = true;

                // Todo : check if user is subscribed to this organization or not
                // Todo : Update the store accordingly
                this.store.subscriptionStore.isSubscribed = false;
            } else {
                // message.error("Error from server response");
                console.error("no access_token in login response");
            }
            if (result.User) {
                this.store.user.name = result.User.Name;
                this.store.user.email = result.User.Email;
                if (result.User.LastVisit) this.store.user.lastVisit = new Date(result.User.LastVisit).toLocaleString();
            }
        }


        history.push("/dashboard");

    };

    clearSession = () => {
        // Clear access token
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("expires_at");
        localStorage.removeItem("userId");
    };

    isLoginValid = () => {
        // Check whether the current time is past the
        // access token's expiry time
        let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
        if (expiresAt == null) {
            return false;
        }
        return new Date().getTime() < expiresAt;
    };
}

// const auth = new Auth();
export default Auth;
