import React, {Component} from 'react';
import {Row, Col} from 'antd';
import usagePurposeIcon from 'assets/icons/UsagePurposes.png';
import downloadData from 'assets/icons/downloadData.png';
import deleteData from 'assets/icons/deleteData.png';
import {withNamespaces} from "react-i18next";
//Store
import {store} from "Provider/store";

import './menuGrid.css';

import {history} from '../../history';

const overview = {
    usagePurpose: 'You will have the ability to mark your preferences',
    manageData: 'View/Modify/Download your personal data.',
    downloadData: 'You can request downloading your personal data.',
    deleteData: 'Permanently or temporarily delete your account and the associated data',
    viewLogs: 'View your consent history/logs',
    userRequests: 'View/Modify/Download your personal data.'
};

class MenuGrid extends Component {
    changeRoute = (path) => {
        history.push(`/dashboard/${path}`);
    };

    render() {
        const {t} = this.props;
        if (store.subscriptionStore.isSubscribed) {
            return (
                <div className='menu-grid fade'>
                    {/* <div className='menu-grid-header'><p>Manage your data</p></div> */}
                    <Row type='flex' justify='center'>
                        <Col className='col-justify-center' xs={12} sm={12} md={6} lg={6} xl={6}>

                            <div className='menu-grid-icon' onClick={() => this.changeRoute('usagepurpose')}>
                                <img style={{width: '100%'}} src={usagePurposeIcon}/>
                            </div>
                            <p className='title'>{t("landingPage.usagePurpose")}</p>
                            <p className='menu-grid-overview-text'>{t("userRequests.description")}</p>

                        </Col>
                        <Col className='col-justify-center' xs={12} sm={12} md={6} lg={6} xl={6}>

                            <div className='menu-grid-icon' onClick={() => this.changeRoute('downloaddata')}>
                                <img style={{width: '100%'}} src={downloadData}/>
                            </div>
                            <p className='title'>{t("landingPage.downloadData")}</p>
                            <p className='menu-grid-overview-text'>{t('downloadData.description')}</p>
                        </Col>
                        <Col className='col-justify-center' xs={12} sm={12} md={6} lg={6} xl={6}>

                            <div className='menu-grid-icon' onClick={() => this.changeRoute('deletedata')}>
                                <img style={{width: '100%'}} src={deleteData}/>
                            </div>
                            <p className='title'>{t("landingPage.deleteData")}</p>
                            <p className='menu-grid-overview-text'>{t('deleteData.description')}</p>
                        </Col>
                        {/* <Col className='col-justify-center' xs={12} sm={12} md={6} lg={6} xl={6}>

                        <div className='menu-grid-icon' onClick={()=>this.changeRoute('managedata')}>
                            <img style={{width:'100%'}} src={downloadData}/>
                        </div>
                            <p className='title'>Manage your data</p>
                            <p className='menu-grid-overview-text'>{overview.manageData}</p>

                    </Col> */}
                        {/* <Col className='col-justify-center' xs={12} sm={12} md={6} lg={6} xl={6}>

                        <div className='menu-grid-icon' onClick={() => this.changeRoute('managedata')}>
                            <img style={{ width: '100%' }} src={downloadData} />
                        </div>
                        <p className='title'>User Requests</p>
                        <p className='menu-grid-overview-text'>{overview.userRequests}</p>

                    </Col> */}
                        <Col className='col-justify-center' xs={12} sm={12} md={6} lg={6} xl={6}>

                            <div className='menu-grid-icon' onClick={() => this.changeRoute('logs')}>
                                <img style={{width: '100%'}} src={usagePurposeIcon}/>
                            </div>
                            <p className='title'>{t("landingPage.viewLogs")}</p>
                            <p className='menu-grid-overview-text'>{t('viewlogs.description')}</p>
                        </Col>
                    </Row>
                </div>
            );
        } else {
            return <div/>
        }
    }
}

export default withNamespaces()(MenuGrid);