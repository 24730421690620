import React, {Component} from 'react';
import {Form, Input, Radio} from 'antd';
import {store, RequestTypes} from '../../Provider/store';
import {withNamespaces} from "react-i18next";

const onFieldsChange = (props, changeValue, allValue) => {
    // console.log(props,changeField,allFields);
};

const RequestForm = Form.create({name: 'requestForm', onValuesChange: onFieldsChange})(
    // eslint-disable-next-line
    class extends React.Component {

        componentWillMount() {
            store.dataRequest.form = this.props.form;
            // this.props.form.onFieldsChange = this.onFieldsChange;
        }

        render() {
            const {visible, onCancel, onCreate, form, t} = this.props;
            const {getFieldDecorator} = form;
            return (

                <Form layout="vertical">
                    {/* <Form.Item label="Title">
              {getFieldDecorator('title', {
                rules: [{ required: true, message: 'Please input the title of collection!' }],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="Description">
              {getFieldDecorator('description')(<Input type="textarea" />)}
            </Form.Item> */}
                    <Form.Item label={t('userRequests.requestType')} className="collection-create-form_last-form-item">
                        {getFieldDecorator('requestType', {
                            initialValue: RequestTypes.dataDownload,
                        })(
                            <Radio.Group>
                                <Radio value={RequestTypes.dataDownload}>{t('landingPage.downloadData')}</Radio>
                                <Radio value={RequestTypes.dataDelete}>{t('landingPage.deleteData')}</Radio>
                                {/* <Radio value={RequestTypes.dataUpdate}>Update Data</Radio> */}
                            </Radio.Group>,
                        )}
                    </Form.Item>
                </Form>

            );
        }
    },
);

export const RequestFormWithNamespace = withNamespaces()(RequestForm);