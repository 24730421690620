import axios from 'axios';

export default class HttpClient {
    constructor(store, auth) {
        this.store = store;
        this.auth = auth;
    }

    fetchOrganization = () => {
        const endpoint = this.store.config.serviceUrls.organization.common;
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(endpoint, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    // retrieving the subscribing methods enabled
    fetchSubscribeMethods = () => {
        const endpoint = this.store.config.serviceUrls.organization.subscribeMethods;
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(endpoint, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    // checking subscription status
    fetchSubscriptionStatus = () => {

        // Todo: Need to include this to common config or separate this as different config
        const endpoint = this.store.config.baseUrl + `/v1/user/organizations/` + this.store.config.organizationId;
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(endpoint, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    // subscribing to organization
    addUserToOrg = (subscriptionKey) => {

        const endpoint = this.store.config.serviceUrls.organization.addUser;
        const token = this.auth.token;

        let body = {
            userid: this.auth.userId,
            subscribekey: subscriptionKey
        };

        if (token) {
            try {
                return axios.post(endpoint, body, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }

    };

    fetchImage = (imageURL) => {
        if (!imageURL) {
            return;
        }
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(imageURL, {headers: {'Authorization': `Bearer ${token}`}, responseType: 'arraybuffer'})
                    .then(response => {
                        let imageData = new Buffer(response.data, 'binary').toString('base64');
                        let imageURI = `data:${response.headers["content-type"]};base64,${imageData}`;
                        return imageURI;
                    })
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    fetchPurposesAndConsents = () => {
        const endpoint = this.store.config.serviceUrls.organization.purposesAndConsents;
        const token = this.auth.token;
        const userId = this.auth.userId;
        if (token) {
            try {
                return axios.get(`${endpoint}/${userId}/consents`, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    updateAttributeStatus = (consentsId, purposeId, attributeiId, value) => {
        const endpoint = this.store.config.serviceUrls.organization.purposesAndConsents;
        const token = this.auth.token;
        const userId = this.auth.userId;
        let body = {...value};
        if (token) {
            try {
                return axios.patch(`${endpoint}/${userId}/consents/${consentsId}/purposes/${purposeId}/attributes/${attributeiId}`, body, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    updateAllAttributeStatus = (consentsId, purposeId, value) => {
        const endpoint = this.store.config.serviceUrls.organization.purposesAndConsents;
        const token = this.auth.token;
        const userId = this.auth.userId;
        let body = {...value};
        if (token) {
            try {
                return axios.post(`${endpoint}/${userId}/consents/${consentsId}/purposes/${purposeId}/status`, body, {headers: {'Authorization': `Bearer ${token}`}})
            } catch (error) {
                console.error('Axios Error :', error);
            }
        } else {
            console.log('token doesnt exist');
        }
    };

    fetchLogs = () => {
        const endpoint = this.store.config.serviceUrls.organization.logs;
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(endpoint, {headers: {'Authorization': `Bearer ${token}`}});
            } catch (error) {
                console.error(`Axios Error : `, error);
            }
        }
    };

    getAlldataDownload = () => {
        const endpoint = this.store.config.serviceUrls.organization.dataDownload;
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(endpoint, {headers: {'Authorization': `Bearer ${token}`}});
            } catch (error) {
                console.error('Axios Error : ', error);
            }
        }
    };

    postDataDownload = () => {
        const endpoint = this.store.config.serviceUrls.organization.dataDownload;
        const token = this.auth.token;
        let body = {};
        if (token) {
            try {
                return axios.post(endpoint, body, {headers: {'Authorization': `Bearer ${token}`}});
            } catch (error) {
                console.error('Axios Error : ', error);
            }
        }
    };

    getAlldataDelete = () => {
        const endpoint = this.store.config.serviceUrls.organization.dataDelete;
        const token = this.auth.token;
        if (token) {
            try {
                return axios.get(endpoint, {headers: {'Authorization': `Bearer ${token}`}});
            } catch (error) {
                console.error('Axios Error : ', error);
            }
        }
    };

    postDataDelete = () => {
        const endpoint = this.store.config.serviceUrls.organization.dataDelete;
        const token = this.auth.token;
        let body = {};
        if (token) {
            try {
                return axios.post(endpoint, body, {headers: {'Authorization': `Bearer ${token}`}});
            } catch (error) {
                console.error('Axios Error : ', error);
            }
        }
    }
}