import React, {Component} from "react";

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import {observer} from "mobx-react";

@observer
class CustomPhoneNumber extends Component {

    constructor(props) {
        super(props);

        const value = props.value || {};

        this.state = {
            rawValue: "+1"
        };

        this.triggerChange({rawValue: "+46"});
    }


    componentDidMount() {
        this.setState({rawValue: '+46'});
        this.triggerChange({rawValue: "+46"});
    }

    triggerChange = changedValue => {
        // Should provide an event to pass value to Form.
        const {onChange} = this.props;
        if (onChange) {
            onChange({
                ...this.state,
                ...changedValue,
            });
        }
    };

    render() {

        const handleOnChange = (value) => {
            this.setState({rawValue: value});
            this.triggerChange({rawValue: value});
        };

        return (
            <PhoneInput defaultCountry={'se'} value={this.state.rawValue}
                        dropdownStyle={{border: "0 !important",  backgroundColor: "transparent !important"}}
                        inputExtraProps={{placeholder: 'Mobile Number', id: "phone-form-control"}} onChange={handleOnChange}/>
        )
    }
}

export default CustomPhoneNumber;