import React, {Component} from "react";
import {observer} from 'mobx-react';
import {Spin, Icon} from 'antd';
// import Auth from "authorization/auth";
import {history} from "../../history";
import {store, auth} from "../../Provider/store";

import Header from '../Header';
import CoverImage from '../CoverImage';
import {Logo} from '../Login/Logo';
import OrganizationInfo from '../OrganizationInfo';
import {OrganizationOverview} from '../OrganizationInfo/organizationOverview';
import ManageMenu from '../ManageMenu';
//dashboard css
import './dashboard.css';
import {withNamespaces} from "react-i18next";

const antIcon = <Icon type="loading" style={{fontSize: 24}} spin/>;

@observer
class DefaultLayout extends Component {
    componentDidMount() {

        store.fetchOrganization();
        if (!store.subscriptionStore.isSubscribed) {
            store.handleSubscription();
        }
        console.log("DEBUG SUBSCRIPTION STATUS");
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {

        const {from} = this.props.location.state || {from: {pathname: '/'}};
        const {isSubscribed} = store.subscriptionStore;
        const {t} = this.props;

        const {isFetching, error} = store.organizationStore;
        const isLoading = store.subscriptionStore.isFetching;
        const subscriptionMethod = store.subscriptionStore.method;

        if (!isSubscribed && subscriptionMethod === "Key-Based") {
            return (
                <div className="router-content-container">
                    {isFetching && isSubscribed ?
                        <div className='dashboard-spin'><Spin tip={t('fetchingMessage')} indicator={antIcon}/>
                        </div> : (
                            <React.Fragment>
                                <ManageMenu match={this.props.match}/>
                            </React.Fragment>)}
                    {error && <div className='dashboard-error'>{error}</div>}
                    <div className='dashboard-footer'>
                        <Logo/>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <Header/>
                    {isFetching ?
                        <div className='dashboard-spin'><Spin tip={t('fetchingMessage')} indicator={antIcon}/>
                        </div> : (
                            !error && <React.Fragment>
                                <CoverImage/>
                                <OrganizationInfo/>
                                <OrganizationOverview/>
                                <ManageMenu match={this.props.match}/>
                            </React.Fragment>)}
                    {error && <div className='dashboard-error'>{error}</div>}
                    <div className='dashboard-footer'>
                        <Logo/>
                    </div>
                </div>
            )
        }

    }
}

export default withNamespaces()(DefaultLayout);
