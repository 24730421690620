import React, { Component } from "react";
import { observer } from "mobx-react";
import { Route, Link, Switch, Router, Redirect } from "react-router-dom";

import MenuGrid from "./MenuGrid";
import Tab from "Components/Tab";

import { Row, Col, Icon, Spin } from "antd";
import { history } from "../../history";
import CollapseUsage from "Components/CollapseUsage";
import DataRequestTable from "Components/DataRequestTable";
import LogsTable from "Components/Table";
import Timeline from "Components/Timeline";
import { store, Status } from "../../Provider/store";
import OpenInApp from "../OpenInApp/OpenInApp";
import SubscribeToOrganization from "../SubscribeToOrganization/SubscribeToOrganization";
import { withNamespaces } from "react-i18next";

//Data for timeline component
const data = {
  nodes: [
    {
      title: "Acknowledged",
      dataIndex: Status.acknowledged
    },
    {
      title: "Processed",
      dataIndex: "processed"
    }
  ]
};

const UsagePurpose = withNamespaces()(({ t }) => (
  <Tab title={t("landingPage.usagePurpose")}>
    <CollapseUsage />
  </Tab>
));

const deleteData = observer(
  withNamespaces()(({ t }) => {
    const dataDeleteRequest = store.dataDeleteRequest;
    let status = null;
    let requestedTime = null;
    if (store.dataRequest.isFetching) {
      const antIcon = (
        <Icon type={t("loading")} style={{ fontSize: 24 }} spin />
      );
      return (
        <div className="dashboard-spin">
          <Spin tip={t("userRequests.fetchingMessage")} indicator={antIcon} />
        </div>
      );
    }
    if (dataDeleteRequest.length > 0) {
      status =
        dataDeleteRequest[dataDeleteRequest.length - 1]["status"] ===
        (Status.processedWithAction || Status.processedWithoutAction)
          ? "processed"
          : dataDeleteRequest[dataDeleteRequest.length - 1]["status"];
      requestedTime =
        dataDeleteRequest[dataDeleteRequest.length - 1]["requested"];
    }

    return (
      <Tab title={t("deleteData.title")}>
        {/* <div><Row><Timeline status={store.dataDeleteRequest && store.dataDeleteRequest[store.dataDeleteRequest.length - 1]['status']} /></Row></div> */}
        <div>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 16, offset: 4 }}
              lg={{ span: 12, offset: 6 }}
            >
              {status && status.length > 0 && (
                <Timeline
                  mode="horizontal"
                  data={{
                    ...data,
                    nodes: [
                      {
                        title: "Initialized",
                        dataIndex: Status.initiated,
                        subtitle: requestedTime
                      },
                      ...data.nodes
                    ],
                    status: status
                  }}
                />
              )}
              {!status && (
                <div style={{ textAlign: "center" }}>
                  <p>{t("deleteData.emptyMessage")}</p>
                  <br />
                  <p>
                    {t("deleteData.createOne")}{" "}
                    <Link to="/dashboard/managedata">
                      {t("dashboard.userRequest")}
                    </Link>{" "}
                    {t("deleteData.andThen")}{" "}
                    <b>{t("userRequests.newRequest")}!.</b>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Tab>
    );
  })
);
const downloadData = observer(
  withNamespaces()(({ t }) => {
    const dataDownloadRequest = store.dataDownloadRequest;
    let status = null;
    let requestedTime = null;
    if (store.dataRequest.isFetching) {
      const antIcon = (
        <Icon type={t("loading")} style={{ fontSize: 24 }} spin />
      );
      return (
        <div className="dashboard-spin">
          <Spin tip={t("userRequests.fetchingMessage")} indicator={antIcon} />
        </div>
      );
    }
    if (dataDownloadRequest.length > 0) {
      status =
        dataDownloadRequest[dataDownloadRequest.length - 1]["status"] ===
        (Status.processedWithAction || Status.processedWithoutAction)
          ? "processed"
          : dataDownloadRequest[dataDownloadRequest.length - 1]["status"];
      requestedTime =
        dataDownloadRequest[dataDownloadRequest.length - 1]["requested"];
    }
    return (
      <Tab title={t("downloadData.title")}>
        {/* <div><Row><Timeline status={store.dataDownloadRequest && store.dataDownloadRequest[store.dataDownloadRequest.length - 1]['status']} /></Row></div> */}
        <div>
          <Row>
            <Col
              xs={{ span: 20, offset: 2 }}
              sm={{ span: 20, offset: 2 }}
              md={{ span: 16, offset: 4 }}
              lg={{ span: 12, offset: 6 }}
            >
              {status && (
                <Timeline
                  mode="horizontal"
                  data={{
                    ...data,
                    nodes: [
                      {
                        title: "Initialized",
                        dataIndex: Status.initiated,
                        subtitle: requestedTime
                      },
                      ...data.nodes
                    ],
                    status: status
                  }}
                />
              )}
              {!status && (
                <div style={{ textAlign: "center" }}>
                  <p>{t("downloadData.emptyMessage")}</p>
                  <br />
                  <p>
                    {t("deleteData.createOne")}{" "}
                    <Link to="/dashboard/managedata">
                      {t("dashboard.userRequest")}
                    </Link>{" "}
                    {t("deleteData.andThen")}{" "}
                    <b>{t("userRequests.newRequest")}!.</b>
                  </p>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Tab>
    );
  })
);
const manageData = withNamespaces()(({t}) => (
  <Tab title={t("dashboard.userRequest")}>
    <div>
      <Row>
        <DataRequestTable />
      </Row>
    </div>
  </Tab>
));
const Logs = withNamespaces()(({t}) => (
  <Tab title={t("logs")}>
    <div>
      <Row>
        <LogsTable />
      </Row>
    </div>
  </Tab>
));

class index extends Component {
  componentDidMount() {
    // console.log('match',this.props.match);
    store.fetchDataRequest();
  }

  render() {
    const { match } = this.props;
    return (
      <div className="router-container">
        <Switch>
          <Route
            path="/dashboard/subscribe"
            component={SubscribeToOrganization}
          />
          <Route path="/dashboard/menu" component={MenuGrid} />
          <Route path="/dashboard/usagepurpose" component={UsagePurpose} />
          <Route path="/dashboard/deletedata" component={deleteData} />
          <Route path="/dashboard/downloaddata" component={downloadData} />
          <Route path="/dashboard/managedata" component={manageData} />
          <Route path="/dashboard/logs" component={Logs} />
          <Redirect exact from="/dashboard" to="/dashboard/menu" />
        </Switch>
        <OpenInApp />
      </div>
    );
  }
}

export default index;
