import React, {Component} from "react";

//Store
import {store, auth} from "../../Provider/store";
import {observer} from "mobx-react";

//Styles
import "./privacypolicy.css";

import defaultLogo from "assets/icons/igrant.io_200X200.jpg";

@observer
class PrivacyPolicy extends Component {
    getPrivacyPolicy(orgID) {
        if (orgID === "5de11ef088ae12000184f7c4") {
            return "<h1>This is TABY</h1>";
        } else {
            return "<h1>This is not TABY</h1>";
        }
    }

    render() {
        const {url} = store.config.logo;
        console.log(store.config.organizationId);

        return store.config.organizationId !== "5de11ef088ae12000184f7c4" ? (
            <div className={"page-container"}>
                <div className={"content-container"}>
                    <div className={"logo-container"}>
                        <div className="logo">
                            <img src={url ? url : defaultLogo}/>
                        </div>
                    </div>
                    <div className={"privacy-policy-text"}>
                        <div id={"section1"}>
                            <h4>DEFAULT POLICY</h4>
                            <p className={"privacy-content"}>
                                This is the default policy page for all the usage purposes. This
                                is to inform you that we use your data only for the purpose we
                                have shown. For each of your personal data attributes, you can
                                view and will be used only if you consent us to use it. You can
                                view the current status for all your personal data, the purposes
                                for which they are used and provide you the choice to opt-in or
                                opt-out.
                            </p>
                        </div>

                        <div id={"section2"}>
                            <h4>MEETING AND EVENT REGISTRATIONS</h4>
                            <p className={"privacy-content"}>
                                For the meetings and events registrations conducted by us, we
                                use your personal data. You may, at any time opt-out of the
                                usage of your data at the attribute level or at the purpose
                                level via our privacy dashboard or by using the opt-out features
                                available in the iGrant.io app.
                            </p>
                        </div>

                        <div id={"section3"}>
                            <h4>NEWSLETTERS AND INFORMATION MAILS</h4>
                            <p className={"privacy-content"}>
                                As a member you receive timely information via our newsletters.
                                You may, at any time opt-out of the usage of your data at the
                                attribute level or at the purpose level via our privacy
                                dashboard or by using the opt-out features available in the
                                iGrant.io app.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className={"page-container"}>
                <div className={"content-container"}>
                    <div className={"logo-container"}>
                        <div className="logo">
                            <img src={url ? url : defaultLogo}/>
                        </div>
                    </div>
                    <div className={"privacy-policy-text"}>
                        <div id={"section1"}>
                            <h2>Personuppgiftshantering på Täby Golf</h2>
                            <p className={"privacy-content"}>
                                Det finns tre regelverk som styr databehandling av
                                personuppgifter i Golfsverige. Dessa regler gäller för alla
                                fysiska personer (golfspelare, ledare och anställda inom golfen)
                                samt alla juridiska personer (alla golforganisationer). De
                                styrande regelverken är Dataskyddsförordningen (GDPR), Idrottens
                                Uppförandekod samt GIT-bestämmelserna. Genom att använda våra
                                tjänster accepterar du vår dataskyddspolicy och vår behandling
                                av dina personuppgifter. Du godkänner att Täby Golf AB och Täby
                                Golfklubb (nedan kallade Täby Golf) använder elektroniska
                                kommunikationskanaler för att skicka information till dig. Du
                                kan enkelt kontrollera vilken data som hanteras kring dig samt
                                för vilket syfte. Detta gör du genom att logga in på din
                                integritetspanel (här). Har du frågor om GDPR eller någon av de
                                andra regelverken kan du alltid kontakta klubbens kansli
                                info@xxxxxxxxxx.se
                            </p>
                        </div>

                        <div id={"section2"}>
                            <h2>Allmänna villkor</h2>
                            <h4>
                                1. Golf-ID och personuppgifter som hanteras i kontraktuellt
                                syfte
                            </h4>
                            <p className={"privacy-content"}>
                                När golfspelaren blir medlem och får ett Golf-ID blir han/hon
                                automatiskt bunden av stadgar, GIT bestämmelserna och beslut som
                                fattas av golforganisationen. GIT bestämmelserna styr all
                                behandling av personuppgifter som återfinns i GIT. Genom
                                GIT-bestämmelserna regleras också att golfklubben/golfbolaget är
                                personuppgiftsansvarig för huvuddelen av databehandlingarna i
                                GIT. SGF är i de fallen personuppgiftsbiträde till
                                golfklubbarna/golfbolagen. I en del andra fall som exempelvis
                                förbundstävlingar och tidningen Svensk Golf är SGF
                                personuppgiftsansvarig. SGF, GDF, golfklubbarna, golfbolagen,
                                golfspelarna och ett antal andra organisationer och bolag (tex
                                klubbarnas egna driftsbolag) är juridiskt bundna av
                                GIT-bestämmelserna. Medlemsinformation delas endast med tredje
                                part om uttryckligt samtycke finns (se paragraf 3 nedan) med
                                undantag för företag som agerar biträden till klubben eller är
                                en naturlig del av klubbens verksamhet, så som
                            </p>
                            <ul>
                                <li>SGF</li>
                                <li>GDF</li>
                                <li>Teeview (golfträning)</li>
                                <li>Kansliet.se (redovisning)</li>
                                <li>iGrantX (GDPR, samtyckeshantering)</li>
                                <li>Svensk Golf (Golftidning)</li>
                                <li>Kommunen (LOK-stöd samt Aktivitetsstöd….)</li>
                                <li>...</li>
                            </ul>

                            <p className={"privacy-content"}>
                                Täby Golf tillämpar strikt GIT access dvs endast de individer
                                som har ett uttryckligt ansvar att behandla medlemsdata t ex i
                                samband med tävlingar, juniorverksamhet, styrelsearbete etc, har
                                access till GIT. Dessa individer ha även genomgått utbildning i
                                GDPR. Medlemsdata som behandlas i GIT är följande:
                            </p>
                            <ul>
                                <li>Golf-ID</li>
                                <li>Namn</li>
                                <li>Adress</li>
                                <li>Telefonnummer</li>
                                <li>E-postadress</li>
                                <li>Handikapp (gällande samt revisionshistorik)</li>
                                <li>
                                    Historik kring sällskapsrundor (tidpunkt, spelsällskap samt
                                    registrerad score)
                                </li>
                                <li>
                                    Information kring tävlingsrundor (tidpunkt, spelsällskap,
                                    registrerad score samt placering)
                                </li>
                            </ul>
                            <p className={"privacy-content"}>
                                Informationen finns lagrad 24 månader efter avslutat medlemskap.
                            </p>
                            <h4>
                                2. Behandling av personuppgifter utanför GIT - legitimt intresse
                            </h4>
                            <p className={"privacy-content"}>
                                E-post hantering faller utanför GIT-bestämmelsen och RF:s
                                Uppförandekod. Täby Golf kommer att spara e-post högst 24
                                månader om det behövs, annars raderar vi informationen direkt.
                            </p>
                            <p className={"privacy-content"}>Persondata som behandlas:</p>
                            <ul>
                                <li>Namn</li>
                                <li>Golf-ID</li>
                                <li>E-postadress</li>
                            </ul>
                            <h4>3. Hantering av personuppgifter som kräver samtycke</h4>
                            <p className={"privacy-content"}>
                                På Täby Golf använder vi samtycke som laglig grund för viss
                                hantering av dina personuppgifter. Vi anser att detta ökar
                                transparensen kring klubbens hantering av personlig data men det
                                stärker också individens kontroll över vilken persondata som
                                används och till vilket syfte. All samtyckeshantering kan ske
                                via Täby Golfs integritetspanel. En uppdateringe i
                                integritetspanelen medför automatisk uppdatering av
                                samtyckeshantering i min Golf där det finns överlapp (TBC).
                            </p>
                            <p className={"privacy-content"}>
                                3.1 Delning av data med tredje part i syfte att generera
                                sponsorintäkter
                            </p>
                            <p className={"privacy-content"}>
                                Delning av medlemsdata till tredje part sker på dina villkor och
                                med ditt uttryckliga samtycke. Täby Golf har, i enlighet med
                                gällande dataskyddsförordning, för avsikt att generera intäkter
                                genom sponsoring. Medlemmar som samtycker till delning av deras
                                data till medverkande sponsorföretag, bidrar till
                                sponsorintäkter till klubben och/ eller kan komma att erhålla
                                erbjudanden från medverkande företag. Medlemmar som samtycker
                                till datadelning har rätt att bestämma vilka företag hens data
                                delas med. Du kan när som helst samtycka till delning av data
                                och du kan när som helst dra tillbaka ditt samtycke. Ditt
                                samtycke dras automatiskt tillbaka när du avregistreras som
                                medlem i klubben. Följande data kan komma att delas (se varje
                                företag). Notera att du endast kan dra tillbaka samtycke på
                                ändamålsnivå och ej per attribut när det gäller sponsoravtal.
                            </p>
                            <p className={"privacy-content"}>
                                3.2 Delning av data med tredje part i syfte att underlätta
                                arbetet på klubben (?)
                            </p>
                            <p className={"privacy-content"}>
                                Täby Golf kan komma att dela data med externa aktörer som verkar
                                på eller i nära samarbete med klubben. Hit räknas följande
                                bolag…..Restaurangen….?
                            </p>
                            <p className={"privacy-content"}>
                                Information som kan delas, efter medlemmens samtycke, är:
                            </p>
                            <ul>
                                <li>Namn</li>
                                <li>Matpreferenser (?)/ allergier</li>
                            </ul>
                            <p className={"privacy-content"}>3.3 Medlemskommunikation</p>
                            <p className={"privacy-content"}>
                                Täby Golf använder elektroniska kommunikationskanaler för att
                                skicka ut information. Man har alltid rätt att kontakta oss och
                                radera sina personuppgifter från våra listor.
                            </p>
                            <p className={"privacy-content"}>
                                Personuppgifter som används i medlemskommunikationen.
                            </p>
                            <ul>
                                <li>Golf-ID</li>
                                <li>E-mail</li>
                                <li>Telefonnummer (?)</li>
                            </ul>
                            <p className={"privacy-content"}>
                                3.4 Fotografering, bildpublicering
                            </p>
                            <p className={"privacy-content"}>
                                Publicering av namn och bild på hemsida eller sociala medier kan
                                vara integritetskänsligt för många människor. Det gäller
                                särskilt för barn. Det finns varken ett generellt tillstånd
                                eller förbud i GDPR och Idrottens uppförandekod mot att
                                publicera vimmelbilder, spelbilder från banan eller bilder på
                                pristagare. Tvärtom är det viktigt med bilder och texter om
                                medlemmarna för att skapa en inkluderande anda och klubbkänsla.
                                Täby Golf behöver ett samtycke innan bilder publiceras.
                            </p>
                            <h4>4. ÖVRIGT</h4>
                            <p className={"privacy-content"}>
                                4.1 Andra rättigheter som registrerad medlem
                            </p>
                            <p className={"privacy-content"}>
                                Portabilitet, rättning av data eller samtycken (min Golf) vs
                                iGrantX (måste automatiseras…), access till data….kontakta
                                kanslien?
                            </p>
                            <p className={"privacy-content"}>4.2 Greenfeeboken</p>
                            <p className={"privacy-content"}>
                                Den klassiska greenfeeboken som ligger i greenfee efter
                                stängningsdags uppfyller inte dataskyddsförordningens
                                bestämmelser. Boken innehåller personuppgifter som är
                                strukturerade och ger mycket information till den som tar del av
                                uppgifterna. Så vi kommer inte längre lägga ut en greenfeebok,
                                utan man får skriva namn, golf id och betalsätt på ett kuvert
                                som skickas in i brevinkastet i receptionen (som är låst).
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
