const Config = (BASE_URL, ORGANIZATION_ID, LOGO_URL) => {
    return {
        baseUrl: BASE_URL,
        organizationId: ORGANIZATION_ID,
        iGrantOrganizationId: "5c1509c75430460001af6232",
        newsLetterConsentId: "5c150a405430460001af6237",
        logo: {
            url: LOGO_URL
        },
        firebase: {
            deeplink: "https://igrant.page.link/openApp"
        },
        serviceUrls: {
            login: `${BASE_URL}/v1.1/users/login`,
            register: `${BASE_URL}/v1/users/register`,
            iGrantRegister: `${BASE_URL}/v1/organization/${ORGANIZATION_ID}/privacy-dashboard/users/register`,
            logout: `${BASE_URL}/v1/user/logout`,
            forgotPassword: `${BASE_URL}/v1/user/password/forgot`,
            validateEmail: `${BASE_URL}/v1/users/validate/email`,
            validatePhone: `${BASE_URL}/v1/users/validate/phone`,
            verifyPhone: `${BASE_URL}/v1/users/verify/phone`,
            verifyOtp: `${BASE_URL}/v1/users/verify/otp`,
            user: {
                details: `${BASE_URL}/v1/user`
            },
            organization: {
                common: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}`,
                addUser: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}/users`,
                subscribeMethods: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}/subscribe-method`,
                purposes: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}/purposes`,
                coverImage: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}/coverimage`,
                logoImage: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}/logoimage`,
                purposesAndConsents: `${BASE_URL}/v1/organizations/${ORGANIZATION_ID}/users`,
                logs: `${BASE_URL}/v1/user/consenthistory?orgid=${ORGANIZATION_ID}&limit=1024`,
                dataDownload: `${BASE_URL}/v1/user/organizations/${ORGANIZATION_ID}/data-download`,
                dataDelete: `${BASE_URL}/v1/user/organizations/${ORGANIZATION_ID}/data-delete`
            },

        },
        askMe: {
            minDays: 30,
            maxDays: 90
        }

    }
};

export default Config;
