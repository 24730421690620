import React, {Component} from "react";
import ReactDOM from "react-dom";
import {Link, Redirect} from "react-router-dom";
//Mobx
import {observer} from "mobx-react";
//Store
import {store} from "Provider/store";
//i18n
import {withNamespaces} from "react-i18next";

//Authorization
// import Auth from "authorization/auth";
import {auth} from 'Provider/store';
import {getSession} from "authorization/utils";
//history
import {history} from "../../history";

//Antd
import {Form, Icon, Input, Button, Checkbox, Divider, Spin} from "antd";
import {antIcon} from "./antIcon";
import {LanguageSelector} from "./LanguageSelector";
import {Logo} from './Logo';

//Styles
import "./login.css";
import loginIcon from 'assets/icons/arrow.svg';
import defaultLogo from 'assets/icons/igrant.io_200X200.jpg';
import OpenInApp from "../OpenInApp/OpenInApp";

/////////////////// Chech token expiry ///////////
/*eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICItYUR1dmxoUE92M2k0MTVWSnU4cHQ5cVZqREZ0VDdFSWNVRzByeXc1cjZVIn0.eyJqdGkiOiI5OTU1MTFjNC01NDYxLTRkYTYtYjY5My0xZGQ0ZTAzODMwOTkiLCJleHAiOjE1NDg4MjQ1NTcsIm5iZiI6MCwiaWF0IjoxNTQ4Nzg4NTU3LCJpc3MiOiJodHRwczovL2lhbS5pZ3JhbnQuaW8vYXV0aC9yZWFsbXMvaWdyYW50LXVzZXJzIiwiYXVkIjoiaWdyYW50LWlvcy1hcHAiLCJzdWIiOiJlNDI5ZTk1Yy04MmIxLTRlMGYtYTQ2OS1jNTM2M2RkMzFlYzAiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiJpZ3JhbnQtaW9zLWFwcCIsImF1dGhfdGltZSI6MCwic2Vzc2lvbl9zdGF0ZSI6ImJkZmI2MWQ3LWY3MDMtNDNiYS1iOGVmLTU1ZDBhYTVlYjFkNCIsImFjciI6IjEiLCJhbGxvd2VkLW9yaWdpbnMiOltdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IlVzZXIxIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidXNlcjFAaWdyYW50LmNvbSIsImdpdmVuX25hbWUiOiJVc2VyMSIsImZhbWlseV9uYW1lIjoiIiwiZW1haWwiOiJ1c2VyMUBpZ3JhbnQuY29tIn0.SK4Dvigdllaf_1YK7PPq15Psd5hqu7xnPJpoPvgzoaEIIVENs2V5OKXNXbXobpVrRjTQjEgpauFs0fsf7Nv4Vr2Iq2hzn6ae_uXCgb_ij9ZRi6mfMfeeb8CS42s1xxGIYAWHGDueVQPBTAyTn0EAX8a8p3eBeD4Ka0KDQYSDkVJse4fliG0kC5V-LF_jnA3dOOhgzTJcHajacLdNTZTBZdg6B37liG3qh3zjLg9JWB9Jm5WUWGkrBDRGd4jrc4kX0j50sOwlZSnGeCzeNmzevUOSe_sHuf_Ud-X8yXamVsIH6qsdeAnArew_UgT9H_92LPl_DjgPMnhWQXCvqDuEnA*/
@observer
class Login extends Component {
    componentWillMount() {

        if (auth.isLoginValid()) {
            auth.isAuthenticated = true;
            auth.accessToken = getSession('access_token');
            auth.refreshToken = getSession('refresh_token');
            auth.userId = getSession('userId');
            store.user.name = getSession('username');
            store.user.email = getSession('email');
            store.user.lastVisit = new Date(getSession('lastVisit')).toLocaleString();


            // This is for handling QR code redirection if already authenticated.
            history.push("/dashboard");

        }
    }

    componentDidMount() {
        const element = ReactDOM.findDOMNode(this);
        element.addEventListener("keydown", e => {
            this.handleKeydown(e);
        });
    }

    handleKeydown(e) {
        if ((e.keyCode === 13) & (e.shiftKey === false)) {
            this.handleLogin();
        }
    }

    clearError = () => {
        if (store.authStore.error) {
            store.authStore.error = '';
        }
    };

    handleLogin = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.username && values.password) {
                    store.authStore.isLoading = true;
                    store.authStore.isRemember = values.remember;
                    auth.login(values.username, values.password);
                }
            }
        });
    };

    render() {
        const {t} = this.props;
        const {from} = this.props.location.state || {from: {pathname: '/'}};
        const {error} = store.authStore;

        const {getFieldDecorator} = this.props.form;
        const loading = store.authStore.isLoading;
        const {url} = store.config.logo;

        return (
            <div className="login-container">
                <div className='login-container-main'>
                    <div className='logo'><img src={url ? url : defaultLogo}/></div>
                    <p className="login-title">{t("signin")}</p>
                    <Form onSubmit={this.handleLogin} className="login-form">
                        <div className='login-input-group'>
                            <Form.Item className="username-input">
                                {getFieldDecorator("username", {
                                    rules: []//[{ required: true, message: t("messages.username") }]
                                })(
                                    <Input
                                        prefix={
                                            <Icon type="user" style={{color: "rgba(0,0,0,.25)"}}/>
                                        }
                                        placeholder={t("username")}
                                        size="large"
                                        onChange={this.clearError}
                                    />
                                )}
                            </Form.Item>
                            <Divider className='login-divider-m0'/>
                            <Form.Item>
                                {getFieldDecorator("password", {
                                    rules: []// [{ required: true, message: t("messages.password") }]
                                })(
                                    <Input
                                        prefix={
                                            <Icon type="lock" style={{color: "rgba(0,0,0,.25)"}}/>
                                        }
                                        type="password"
                                        placeholder={t("password")}
                                        size="large"
                                        className="password-input"
                                        suffix={
                                            loading ? (
                                                <Spin className='login-btn' indicator={antIcon}/>
                                            ) : (
                                                <div><img className='login-btn right-arrow' onClick={this.handleLogin}
                                                          src={loginIcon}/></div>
                                            )
                                        }
                                        onChange={this.clearError}
                                    />
                                )}
                            </Form.Item>
                        </div>
                        {error && <div className='login-error'>{error}</div>}
                        <Form.Item>
                            <div className="login-checkbox">
                                {getFieldDecorator("remember", {
                                    valuePropName: "checked",
                                    initialValue: true
                                })(<Checkbox>{t("rememberme")}</Checkbox>)}
                            </div>
                        </Form.Item>
                        <Divider className="login-divider"/>
                        <div className="login-actions">
                            <Link to={`/forgot-password`}>{t("forgot")}</Link>
                        </div>
                        <div className="register-actions">
                            <span>{t("noAccount")}</span>
                            <Link to={`/register`}>{t("createAccount")}</Link>
                        </div>
                    </Form>
                </div>
                <div className='login-footer-container'>
                    <div className="login-footer">
                        <p className='copyright'>Copyright © 2019 LCubed AB, Sweden. All rights reserved.</p>
                        <LanguageSelector t={t}/>
                        <Logo/>
                    </div>
                </div>

                <OpenInApp/>

            </div>
        );
    }
}

const WrappedLogin = Form.create({name: "normal_login"})(Login);

export default withNamespaces()(WrappedLogin);
