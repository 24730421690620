import libbase64 from "libbase64";

export const getEncodedUsernamePassword = (username, password) => {
	if (
		username &&
		password &&
		typeof username === "string" &&
		typeof password === "string"
	) {
		const usernameb64 = libbase64.encode(username);
		const passwordb64 = libbase64.encode(password);
		return { username: usernameb64, password: passwordb64 };
	} else {
		return;
	}
};

// //get access token
// export const getAccessToken = () => {
// 	let value = localStorage.getItem("access_token");
// 	return value;
// };

// //get refresh token
// export const getRefreshToken = () => {
// 	let value = localStorage.getItem("refresh_token");
// 	return value;
// };

// //get refresh token
// export const getUserId = () => {
// 	let value = localStorage.getItem("userId");
// 	return value;
// };

export const getSession =(key)=>{
	let value = localStorage.getItem(key);
	return value;
};


//Set user details in session storage
export const setSession = authResult => {
	let expireAt = JSON.stringify(
		authResult.expires_in * 1000 + new Date().getTime()
	);
	localStorage.setItem("access_token", authResult.access_token);
	localStorage.setItem("refresh_token", authResult.refresh_token);
	localStorage.setItem("expires_at", expireAt);
	localStorage.setItem("userId", authResult.userId);
	localStorage.setItem("username", authResult.username);
	localStorage.setItem("email", authResult.email);
	localStorage.setItem("lastVisit", authResult.lastVisit);
};
