import React from 'react';
import {Collapse,Radio,Icon,Slider} from 'antd';
// import Config from '../../app.config';
import {store} from 'Provider/store';

const Panel = Collapse.Panel;
const RadioGroup = Radio.Group;

// const MIN_DAYS = store.config.askMe.minDays;
// const MAX_DAYS = store.config.askMe.maxDays;

const SliderWithLabel = (props)=>(
    <div className="label-wrapper">
        <label>{props.min}</label>
        <Slider {...props} />
        <label>{props.max}</label>
        <label className='value'>{props.value} Days</label>
      </div>
)

export const SubCollapse = ({title,consented,days,onRadioClick,onSliderChange,lawfulUsage})=>(
    <Collapse
    className='collapse-usage bg-transparent'
    bordered={false}
    expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
    >
        <Panel className='sub-collapse-panel' header={title} key="1">
        <div className='group'>
        <RadioGroup className='radio-group-small' disabled={lawfulUsage} defaultValue={lawfulUsage?'Allow':null} onChange={onRadioClick} value={consented}>
            <Radio value={'Allow'}>Allow</Radio>
            <Radio value={'Disallow'}>Disallow</Radio>
            <Radio value={'Askme'}>Ask Me</Radio>
        </RadioGroup>
        <SliderWithLabel disabled={consented !== 'Askme'} min={store.config.askMe.minDays} onChange={onSliderChange} max={store.config.askMe.maxDays} value={days} />
        {/* <p>If you choose 'Allow',you are consenting to the use of your personal data permenently for any analytic or third party usage beyond the purpose you have signed up with</p> */}
        </div>
        </Panel>
      </Collapse>
)