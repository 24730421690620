import React, {Component} from "react";

//Store
import {store} from "../../Provider/store";
import {observer} from "mobx-react";

//Styles
import "./openinapp.css";

@observer
class OpenInApp extends Component {

    state = {
        isOpen: true
    };

    getOS = () => {
        let userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    };

    handleClose = () => {
        localStorage.setItem("isOpen", "0");
        this.setState({isOpen: false});
    };

    render() {

        const redirectUrl = store.qrStore.shortLink === "" ? store.config.firebase.deeplink : store.qrStore.shortLink;

        if (this.getOS() === "iOS" && localStorage.getItem("isOpen") === null) {
            return (
                <div className={"openInAppSliver"} style={{display: this.state.isOpen ? 'flex' : 'none'}}>
                    <div className={"appLinkContainer"}>
                        <a href={redirectUrl}>Open in app</a>
                        <a href="#" onClick={this.handleClose} className={"close"}/>
                    </div>

                </div>
            )
        } else if (this.getOS() === "Android" && localStorage.getItem("isOpen") === null) {
            return (
                <div className={"openInAppSliver"} style={{display: this.state.isOpen ? 'flex' : 'none'}}>
                    <div className={"appLinkContainer"}>
                        <a href={redirectUrl}>Open in app</a>
                        <a href="#" onClick={this.handleClose} className={"close"}/>
                    </div>

                </div>
            )
        } else {
            return (
                <div/>
            )
        }

    }

}


export default OpenInApp
