import React, {Component} from "react";
import {observer} from "mobx-react";
import {withNamespaces, Trans} from "react-i18next";
import {Table, Button, Tag, Modal} from "antd";
import {RequestForm, RequestFormWithNamespace} from "./NewRequest";
import {store, Status} from "Provider/store";
import moment from "moment";

const columns = [
    {
        title: <Trans i18nKey="userRequests.requestType">Request Type</Trans>,
        dataIndex: "requestType",
        key: "requestType",
        width: "25%"
    },
    {
        title: <Trans i18nKey="userRequests.requestedTime">Requested Time</Trans>,
        dataIndex: "requested",
        key: "requested",
        sorter: (a, b) =>
            moment(a.requested, "MMM-DD-YYYY HH:mm:ss A") -
            moment(b.requested, "MMM-DD-YYYY HH:mm:ss A"),
        sortDirections: [],
        sortOrder: "descend",
        defaultSortOrder: "descend",
        width: "25%"
    },
    {
        title: <Trans i18nKey="userRequests.status">Status</Trans>,
        key: "status",
        dataIndex: "status",
        width: "25%",
        render: status => (
            <span>
        <Tag
            color={
                (status == Status.initiated && "orange") ||
                (status == Status.acknowledged && "blue") ||
                (status == Status.processedWithAction && "green") ||
                (status == Status.processedWithoutAction && "green")
            }
            key={"tag"}
        >
          <Trans i18nKey={
              (status == Status.initiated && "userRequests." + status) ||
              (status == Status.acknowledged && "userRequests." + status) ||
              (status == Status.cancelled && "userRequests." + status) ||
              (status == Status.processedWithAction && "userRequests." + status) ||
              (status == Status.processedWithoutAction && "userRequests." + status)
          }>{status}</Trans>
        </Tag>
      </span>
        )
    },
    {
        title: <Trans i18nKey="userRequests.closed">Closed</Trans>,
        dataIndex: "closed",
        key: "closed"
    }
];

@observer
class DeleteData extends Component {
    state = {visible: false, status: null, visibleForm: false};

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleOk = e => {
        // console.log(e);
        store.sendDataRequest();
        this.setState({
            visible: false
        });
    };

    handleCancel = e => {
        this.setState({
            visible: false
        });
    };
    // handleRowClick = (record,dataIndex)=>{
    //   return {
    //     onClick: event => {
    //       // console.log(record,dataIndex);
    //       this.setState({status:record['status'],visibleForm:null},()=>{
    //         console.log('Status : ',this.state.status);
    //         this.showModal();
    //       })
    //     }, // click row
    //   };
    // }
    handleNewRequest = () => {
        // console.log('OnNewRequest');
        this.setState({visibleForm: true, status: null}, () => {
            this.showModal();
        });
    };

    componentDidMount() {
        store.fetchDataRequest();
    }

    render() {
        const {isFetching, data} = store.dataRequest;

        console.log("DEBUG FROM DATAREQUEST COMPONENT : ", data);

        const {status, visibleForm} = this.state;
        const {t} = this.props;

        return (
            <React.Fragment>
                <Modal
                    title={t("userRequests.createRequest")}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    okText={t("userRequests.send")}
                >
                    {/* {status && <Timeline>
            <Timeline.Item color={status == Status.initiated ? 'green' : 'blue'}>{Status.initiated}</Timeline.Item>
            <Timeline.Item color={status == Status.acknowledged ? 'green' : 'blue'}>{Status.acknowledged}</Timeline.Item>
            <Timeline.Item color={(status == Status.processedWithAction || status == Status.processedWithoutAction) ? 'green' : 'blue'}>{'Request processed'}</Timeline.Item>
          </Timeline>} */}
                    {visibleForm && <RequestFormWithNamespace/>}
                </Modal>
                <div className="usage-purpose slide-in">
                    <Button
                        type={"primary"}
                        onClick={this.handleNewRequest}
                        style={{float: "right", marginBottom: "1rem", zIndex: 100}}
                        icon="plus"
                        ghost
                    >
                        {t("userRequests.newRequest")}
                    </Button>
                    <Table
                        scroll={{x: true, y: true}}
                        loading={isFetching}
                        columns={columns}
                        dataSource={data}
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(DeleteData);
