import React, { Component } from 'react';
import {withNamespaces, Trans} from "react-i18next";
import { Timeline as TimelineAntd, Icon } from 'antd';
import {store,Status} from 'Provider/store';
import { observer } from 'mobx-react';

//Style
import './timeline.css';

// @observer
// class Timeline extends Component {
//     render() {
//         const {status} = this.props;
//         return (
//             <TimelineAntd mode="alternate">
//                 <TimelineAntd.Item color={status == Status.initiated ? 'green' : 'blue'}>{Status.initiated}</TimelineAntd.Item>
//                 <TimelineAntd.Item color={status == Status.acknowledged ? 'green' : 'blue'}>{Status.acknowledged}</TimelineAntd.Item>
//                 <TimelineAntd.Item color={(status == Status.processedWithAction || status == Status.processedWithoutAction) ? 'green' : 'blue'}>{'Request processed'}</TimelineAntd.Item>
//             </TimelineAntd>
//         )
//     }
// }

class Timeline extends Component {
    static defaultProps={
        mode: "horizontal" || "vertical"
    }

  render() {
    const { data, mode } = this.props;

    console.log("DEBUG FROM TIMELINE : ", data.nodes)

    const lastNode = data.nodes.find(x => x.dataIndex === data.status);
    const lastNodeIndex = lastNode && data.nodes.indexOf(lastNode);
    return (
      <React.Fragment>
        <div className={`timeline-container ${mode}`}>
          {data &&
            data.nodes.map((node, i) => (
              <div key={i} className={`wrapper ${mode}`}>
                {i !== 0 && (
                  <div
                    className={`line ${
                      i <= lastNodeIndex
                        ? `line-solid ${mode}`
                        : `line-dotted ${mode}`
                    }`}
                  />
                )}
                <div className="node-container">
                  <div className={`node-title ${mode}`}>
                    <div>
                      <div className='main-heading'>
                          <Trans i18nKey={
                              (node.title === "Initialized" && "requestTimeline.Initialized") ||
                              (node.title === "Acknowledged" && "requestTimeline.Acknowledged") ||
                              (node.title === "Processed" && "requestTimeline.Processed")
                          }>{node.title}</Trans>

                      </div>
                      {node.subtitle && <div className='subtitle'>{node.subtitle}</div>}
                    </div>
                  </div>
                  <div className="node">
                    {i <= lastNodeIndex && (
                      <div
                        className={
                          i === lastNodeIndex ? "circle animate" : "circle"
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </React.Fragment>
    );
  }
}

export default withNamespaces()(Timeline);