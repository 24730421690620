import React, {Component} from "react";
import ReactDOM from "react-dom";
//Store
// Authorization
import {auth, store} from "Provider/store";
//Mobx
import {observer} from "mobx-react";
//i18n
import {withNamespaces} from "react-i18next";
import {LanguageSelector} from "../Login/LanguageSelector";
//Styles
import "./register.css";
// assets
import defaultLogo from 'assets/icons/igrant.io_200X200.jpg';
import {Logo} from "../Login/Logo";
// custom components
import CustomPhoneNumber from "../CustomPhoneNumber/CustomPhoneNumber";
import CustomOtpInput from "../CustomOtpInput/CustomOtpInput";
// Antd
import {Button, Checkbox, Divider, Form, Input, Spin} from "antd";
import {getSession} from "authorization/utils";


import {antIcon} from "../Login/antIcon";
import {history} from "../../history";
import {setSession} from "../../authorization/utils";
import {Link} from "react-router-dom";
import {services} from "../../Provider/store";
import axios from "axios";

@observer
class Register extends Component {

    state = {
        showOtpScreen: false,
        showPasswordScreen: false,
        registrationScreen: true,
        showSuccessScreen: false,
        consentToNewsletters: false,
        values: {}
    };

    componentWillMount() {
        if (auth.isLoginValid()) {
            auth.isAuthenticated = true;
            auth.accessToken = getSession('access_token');
            auth.refreshToken = getSession('refresh_token');
            auth.userId = getSession('userId');
            store.user.name = getSession('username');
            store.user.email = getSession('email');
            store.user.lastVisit = new Date(getSession('lastVisit')).toLocaleString();
            history.push("/dashboard");
        }
    }

    componentDidMount() {
        const element = ReactDOM.findDOMNode(this);
        element.addEventListener("keydown", e => {
            this.handleKeydown(e);
        });


        console.log("ORGANIZATION ID  : ", store.config.organizationId);

    }

    // handling form submission when enter key is pressed
    handleKeydown(e) {

        if (this.state.registrationScreen) {
            if ((e.keyCode === 13) && (e.shiftKey === false)) {
                this.handleRegistration();
            }
        }

        if (this.state.showPasswordScreen) {
            if ((e.keyCode === 13) && (e.shiftKey === false)) {
                e.preventDefault();
                this.handleCreateAccount();
            }
        }
    }

    clearError = () => {
        if (store.authStore.error) {
            store.authStore.error = '';
        }
    };

    handleRegistration = () => {


        this.clearError();

        this.props.form.validateFields((err, values) => {

            // saving the state
            this.setState({values});

            console.log("Current consent value : ", values.consentToNewsletters);

            // saving consent to newsletter value
            if (store.config.organizationId === store.config.iGrantOrganizationId) {
                this.setState({consentToNewsletters: values.consentToNewsletters});
                console.log("Storing consent value....");
            }

            if (!err) {

                if (values.MobileNumber.rawValue && values.Email) {
                    store.authStore.isLoading = true;

                    // validating email
                    const validateEmailRequest = auth.validateEmail(values.Email);
                    if (validateEmailRequest) {
                        validateEmailRequest.then((res) => {

                            // debug
                            console.log(res);

                            if (res.data.Result !== undefined) {
                                if (!res.data.Result) {
                                    // Email is already in use
                                    store.authStore.isLoading = false;
                                    store.authStore.error = res.data.Message

                                } else {

                                    // Validate phone before proceeding.
                                    const validatePhoneRequest = auth.validatePhone(values.MobileNumber.rawValue);
                                    if (validatePhoneRequest) {
                                        validatePhoneRequest.then((res) => {
                                            if (res.data.Result !== undefined) {
                                                if (!res.data.Result) {

                                                    // Phone is already in use
                                                    store.authStore.isLoading = false;
                                                    store.authStore.error = res.data.Message

                                                } else {

                                                    // Phone is not in use.
                                                    // proceeding to send otp.
                                                    this.handleSendOtp(values.MobileNumber.rawValue, values.Email)
                                                }
                                            } else {
                                                store.authStore.isLoading = false;
                                            }
                                        }).catch((error) => {
                                            this.handleError(error);
                                        })
                                    }
                                }
                            } else {
                                store.authStore.isLoading = false;
                            }
                        }).catch((error) => {
                            this.handleError(error);
                        });
                    }
                }
            }
        });

    };

    handleSendOtp = (mobileNumber, email) => {

        console.log("Current consent value : ", this.state.consentToNewsletters);

        if (mobileNumber && email) {
            store.authStore.isLoading = true;

            // sending otp
            const sendOtpRequest = auth.sendOtp(mobileNumber, email);
            if (sendOtpRequest) {
                sendOtpRequest.then((res) => {
                    store.authStore.isLoading = false;

                    // toggling to otp screen.
                    this.setState({showOtpScreen: true});
                    this.setState({registrationScreen: false});
                    this.setState({showPasswordScreen: false});

                }).catch((error) => {
                    this.handleError(error);
                });
            }
        }


    };

    reSendOtp = () => {

        this.clearError();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.handleSendOtp(values.MobileNumber.rawValue, values.Email);
            }
        });
    };

    verifyOtp = () => {

        this.clearError();

        console.log("Current consent value : ", this.state.consentToNewsletters);


        this.props.form.validateFields((err, values) => {
            if (!err) {

                if (values.otp !== undefined) {
                    if (values.otp.rawValue.length === 6) {
                        store.authStore.isLoading = true;

                        // debug
                        console.log(values);

                        // sending otp
                        const verifyOtpRequest = auth.validateOtp(values.MobileNumber.rawValue, values.otp.rawValue);
                        if (verifyOtpRequest) {
                            verifyOtpRequest.then((res) => {
                                store.authStore.isLoading = false;


                                if (res.data.Result !== undefined) {
                                    if (!res.data.Result) {
                                        // Email is already in use
                                        store.authStore.isLoading = false;
                                        store.authStore.error = res.data.Message

                                    } else {
                                        // Todo: Redirect set password page.
                                        // toggling to password screen.
                                        this.setState({showOtpScreen: false});
                                        this.setState({registrationScreen: false});
                                        this.setState({showPasswordScreen: true});
                                    }
                                } else {
                                    store.authStore.isLoading = false;
                                }


                            }).catch((error) => {
                                this.handleError(error);
                            });
                        }
                    }
                }
            }
        });
    };

    handleCreateAccount = () => {

        this.clearError();

        console.log("Current consent value : ", this.state.consentToNewsletters);

        this.props.form.validateFields((err, values) => {


            if (!err) {


                if (values.Password !== undefined) {
                    if (values.Password.length > 0) {

                        store.authStore.isLoading = true;

                        const registerRequest = auth.register(values.Email, values.Name, values.Password, values.MobileNumber.rawValue);

                        if (registerRequest) {
                            registerRequest.then((res) => {

                                // handle redirection or showing success message and background login
                                this.handleLoginRedirection();


                            }).catch((error) => {
                                this.handleError(error);
                            });
                        }
                    }

                }


            }


        });

    };

    // for background login after account creation.
    handleLoginRedirection = () => {
        this.props.form.validateFields((err, values) => {

            console.log("Current consent value : ", this.state.consentToNewsletters);

            if (!err) {

                store.authStore.isLoading = true;

                const backgroundLoginRequest = auth.backgroundLogin(values.Email, values.Password);

                if (backgroundLoginRequest) {

                    backgroundLoginRequest.then((res) => {

                        if (res.status === 200) {

                            // login success

                            let result = res.data;
                            store.authStore.error = null;

                            // Storing authentication details.
                            if (result.Token) {


                                // handling remember me.
                                if (store.authStore.isRemember) {
                                    setSession({
                                        ...result.Token,
                                        userId: result.User.ID,
                                        username: result.User.Name,
                                        email: result.User.Email,
                                        lastVisit: result.User.LastVisit
                                    });
                                }

                                // handling auth details.
                                store.authStore.accessToken = result.Token.access_token;
                                store.authStore.refreshToken = result.Token.refresh_token;
                                store.authStore.userId = result.User.ID;
                                store.authStore.isAuthenticated = true;

                                // Todo: If organization is iGrant.io handle consent to newsletter
                                // Todo: Send welcome email (From backend)
                                // api url for consenting -> https://demo-api.igrant.io/v1/organizations/5c1509c75430460001af6232/users/5dc036c327a4ad00011f93b2/consents/5dc036ca27a4ad00011f93b3/purposes/5c150a405430460001af6237/status
                                // api payload -> {Consented: "Allow"}

                                // only update consent if user has consented to it.
                                if (this.state.consentToNewsletters) {
                                    const endpoint = store.config.serviceUrls.organization.purposesAndConsents;
                                    const token = store.authStore.accessToken;
                                    const userId = store.authStore.userId;
                                    if (token) {
                                        try {
                                            const request = axios.get(`${endpoint}/${userId}/consents`, {headers: {'Authorization': `Bearer ${token}`}});
                                            if (request) {
                                                request
                                                    .then(res => {
                                                        if (res.status === 200) {
                                                            store.purposesAndConsents.data = res.data['ConsentsAndPurposes'];
                                                            store.purposesAndConsents.ID = res.data['ID'];
                                                            store.purposesAndConsents.loadingUiStore = store.mapToLoadingUiStore([...store.purposesAndConsents.data]);
                                                            store.purposesAndConsents.isFetching = false;

                                                            // update the purpose to allow
                                                            // store.updateAllAttribute("Allow", "5c150a405430460001af6237");


                                                            let consentsId = store.purposesAndConsents.ID;


                                                            const endpoint = store.config.serviceUrls.organization.purposesAndConsents;
                                                            const token = store.authStore.accessToken;
                                                            const userId = store.authStore.userId;
                                                            const purposeId = store.config.newsLetterConsentId;

                                                            let body = {Consented: "Allow"};

                                                            if (token) {
                                                                try {
                                                                    const request = axios.post(`${endpoint}/${userId}/consents/${consentsId}/purposes/${purposeId}/status`, body, {headers: {'Authorization': `Bearer ${token}`}});


                                                                    if (request) {
                                                                        request
                                                                            .then(res => {
                                                                                if (res.status === 200) {
                                                                                    let responseData = res.data;
                                                                                    if (responseData['ConsentsAndPurposes']) {
                                                                                        store.purposesAndConsents.data = responseData['ConsentsAndPurposes'];//Push updated purpose into store
                                                                                        // message.success('Successfully updated all attributes.');
                                                                                        store.purposesAndConsents.loadingUiStore[purposeId]['isLoading'] = false;//Set loading mask as true
                                                                                    } else {
                                                                                        console.log('failed to update all attribute.Response doesnt contain "ConsentsAndPurposes"');
                                                                                        store.purposesAndConsents.loadingUiStore[purposeId]['isLoading'] = false;//Set loading mask as true
                                                                                    }
                                                                                }
                                                                            })
                                                                            .catch(error => {
                                                                                store.purposesAndConsents.loadingUiStore[purposeId]['isLoading'] = false;//Set loading mask as true
                                                                                // message.error('Failed to update all attribute.');
                                                                                store.restore();
                                                                                console.error(error);
                                                                            })
                                                                    }
                                                                } catch (error) {
                                                                    console.error('Axios Error :', error);
                                                                }
                                                            } else {
                                                                console.log('token doesnt exist');
                                                            }
                                                        }
                                                    })
                                                    .catch(error => console.error(error))
                                            }
                                        } catch (error) {
                                            console.error('Axios Error :', error);
                                        }
                                    } else {
                                        console.log('token doesnt exist');
                                    }

                                }


                            } else {
                                // message.error("Error from server response");
                                console.error("no access_token in login response");
                            }

                            store.authStore.isLoading = false;

                            // Toggle to registration success section.
                            this.setState({showOtpScreen: false});
                            this.setState({registrationScreen: false});
                            this.setState({showPasswordScreen: false});
                            this.setState({showSuccessScreen: true});


                        }


                    }).catch((error) => {
                        this.handleError(error);
                    });


                }


            }

        });
    };

    handleError = (error) => {
        if (error.response !== undefined) {
            if (error.response.data.Message !== undefined) {
                store.authStore.error = error.response.data.Message
            } else {
                store.authStore.error = error.response.data.error_description
            }
        }

        store.authStore.isLoading = false;
        console.error(error);
    };

    redirectToDashboard = () => {
        history.replace('/dashboard');
    };


    render() {
        const {t} = this.props;
        const {url} = store.config.logo;
        const {error} = store.authStore;

        const {showOtpScreen, registrationScreen, showPasswordScreen, showSuccessScreen} = this.state;
        const formValues = this.state.values;
        const {getFieldDecorator} = this.props.form;

        const loading = store.authStore.isLoading;


        return (
            <div className="registration-container">
                <div className='registration-container-main'>
                    <div className='logo'><img src={url ? url : defaultLogo}/></div>
                    <p className="registration-title"
                       style={{display: (!showSuccessScreen ? 'block' : "none")}}>{t("createAccountPageTitle")}</p>


                    {/* Registration form */}
                    <Form style={{display: (registrationScreen ? 'block' : "none")}} onSubmit={this.handleRegistration}
                          className="registration-form">
                        <div className='registration-input-group'>
                            <Form.Item className="name-input registration-form-input">
                                {getFieldDecorator("Name", {
                                    rules: []//[{ required: true, message: t("messages.username") }]
                                })(
                                    <Input
                                        placeholder={t("name")}
                                        size="large"
                                        onChange={this.clearError}
                                    />
                                )}
                            </Form.Item>
                            <Divider className='registration-divider-m0'/>
                            <Form.Item className="email-input registration-form-input">
                                {getFieldDecorator("Email", {
                                    rules: []//[{ required: true, message: t("messages.username") }]
                                })(
                                    <Input
                                        type="email"
                                        placeholder={t("email")}
                                        size="large"
                                        onChange={this.clearError}
                                    />
                                )}
                            </Form.Item>
                            <Divider className='registration-divider-m0'/>
                            <Form.Item className="mobile-number-input registration-form-input">
                                {getFieldDecorator('MobileNumber', {
                                    rules: [],
                                })(
                                    <CustomPhoneNumber/>
                                )}
                            </Form.Item>

                        </div>

                        {/* Handling consent to newsletter and other info for iGrant.io organization */}
                        {
                            (store.config.organizationId === store.config.iGrantOrganizationId) ?
                                <Form.Item>
                                    <div className="registration-checkbox">
                                        {getFieldDecorator("consentToNewsletters", {
                                            valuePropName: "checked",
                                            initialValue: true
                                        })(<Checkbox>I consent to receiving newsletters and information from <a
                                            href="https://igrant.io">iGrant.io</a>, I understand I can opt out anytime
                                            from <a
                                                href="https://privacy.igrant.io">privacy.igrant.io</a></Checkbox>)}
                                    </div>
                                </Form.Item> : null
                        }


                        {/* Registration continue */}
                        <div className="register-continue-box">
                            {loading ? <Spin className='login-btn' indicator={antIcon}/> :
                                <Button className="register-continue-button" type="primary"
                                        onClick={this.handleRegistration}>Continue</Button>}

                        </div>

                        {/* Registration input errors */}
                        {error && <div className='registration-error'>{error}</div>}

                        <Divider className="registration-divider"/>

                        {/* Forgot password section */}
                        <div className="forgot-password-actions">
                            <Link to={`/forgot-password`}>{t("forgot")}</Link>
                        </div>

                        {/* Sign in section */}
                        <div className="register-actions">
                            <span>{t("haveAccount")}</span>
                            <Link to={`/login`}>{t("signinLink")}</Link>
                        </div>
                    </Form>

                    {/*  OTP Screen  */}
                    <div style={{display: (showOtpScreen ? 'block' : "none")}} className="registration-otp-screen">
                        <div className="registration-otp-screen-message">
                            <h3>We'll need to verify you</h3>
                            <p>A text message with 6 digit verification code was just send to <span
                                className="otp-phone-number">{formValues.MobileNumber !== undefined ? formValues.MobileNumber.rawValue : ""}</span>
                            </p>
                        </div>
                        <Form onSubmit={this.verifyOtp}
                              className="registration-form otp-form">
                            <Form.Item className="otp-input registration-form-input">
                                {getFieldDecorator("otp", {
                                    rules: []//[{ required: true, message: t("messages.username") }]
                                })(
                                    <CustomOtpInput/>
                                )}
                            </Form.Item>

                            {/* OTP continue */}
                            <div className="register-continue-box">
                                {loading ? <Spin className='login-btn' indicator={antIcon}/> :
                                    <Button className="register-continue-button" type="primary"
                                            onClick={this.verifyOtp}>Continue</Button>}
                            </div>

                            {/* OTP input errors */}
                            {error && <div className='registration-error'>{error}</div>}

                            <Divider className="registration-divider"/>
                            <div className="register-otp-resend">
                                <span>Haven't received yet ?</span><a
                                onClick={this.reSendOtp}> Resend
                                OTP</a>
                            </div>
                        </Form>
                    </div>

                    {/* Password Screen */}
                    <div style={{display: (showPasswordScreen ? 'block' : "none")}}
                         className="registration-password-screen">
                        <div className="registration-otp-screen-message">
                            <h3>You'll need a password</h3>
                            <p>Make sure it's 8 character or more.</p>
                        </div>
                        <Form onSubmit={this.handleCreateAccount}
                              className="registration-form">
                            <div className='registration-input-group'>
                                <Form.Item className="name-input registration-form-input">
                                    {getFieldDecorator("Password", {
                                        rules: []//[{ required: true, message: t("messages.username") }]
                                    })(
                                        <Input
                                            type="password"
                                            placeholder={t("password")}
                                            size="large"
                                            onChange={this.clearError}
                                        />
                                    )}
                                </Form.Item>
                            </div>
                            {/* Registration continue */}
                            <div className="register-continue-box">
                                {loading ? <Spin className='login-btn' indicator={antIcon}/> :
                                    <Button className="register-continue-button" type="primary"
                                            onClick={this.handleCreateAccount}>Create account</Button>}

                            </div>

                            {/* Registration input errors */}
                            {error && <div className='registration-error'>{error}</div>}
                        </Form>
                    </div>

                    {/* User created successfully */}
                    <div style={{display: (showSuccessScreen ? 'block' : "none")}} className="registration-user-screen">
                        <div className="registration-user-screen-message">
                            <h3>You are now a registered user.</h3>
                            <p>Proceed to <a onClick={this.redirectToDashboard}>{t("dashboardLink")}</a></p>
                        </div>
                    </div>
                </div>

                <div className='registration-footer-container'>
                    <div className="registration-footer">
                        <p className='copyright'>Copyright © 2019 LCubed AB, Sweden. All rights reserved.</p>
                        <LanguageSelector t={t}/>
                        <Logo/>
                    </div>
                </div>
            </div>
        );
    }
}

const WrappedRegistration = Form.create({name: "normal_registration"})(Register);

export default withNamespaces()(WrappedRegistration)
